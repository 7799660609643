
import { defineComponent } from 'vue';
import SupplierSidebar from '@/components/SupplierSidebar.vue';

const data = {
};

export default defineComponent({
  name: 'SupplierOrders',
  data() {
    return data;
  },
  components: {
    SupplierSidebar,
  },
  methods: {
  },
});
