
import { defineComponent } from 'vue';
import axios from 'axios';

export default defineComponent({
  name: 'venteDirectList',
  components: {
  },
  data() {
    return {
      barcode: '',
      loading: false,
      libelle: '',
      simpleVersion: true,
      timeout: 0,
      product: [],
      order: Array<any>(),
    };
  },
  watch: {
    barcode: {
      handler(newBarcode) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.searchProduct, 300, newBarcode);
        // this.searchProduct(newBarcode);
      },
    },
  },
  computed: {
    orderTotal() {
      const initialValue = 0;
      return (this as any).order.reduce(
        // eslint-disable-next-line max-len
        (previousValue: number, currentValue: any) => previousValue + this.calculatePrice(currentValue.pv) * currentValue.quantite,
        initialValue,
      );
    },
  },
  methods: {
    searchProduct(ean: number) {
      axios.get(`/fullproduct/${ean}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      }).then((response) => {
        console.log(response.data);
        this.product = response.data;
      });
    },
    calculatePrice(price:number) {
      const priceWithTVa = price * 1.077;
      const roundUpPrice = Math.ceil(priceWithTVa);
      const roundUptoNext10 = Math.ceil(roundUpPrice / 10) * 10;
      const difference = 1 - (roundUpPrice / roundUptoNext10);
      return difference < 0.2 ? roundUptoNext10 : roundUpPrice;
    },
    displayCurrency(amount: number) {
      return new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(amount).replace(' ', '\'');
    },
    addToCart(product: any) {
      // search in order if product is already existing
      const foundProduct = this.order.find((orderProduct) => orderProduct.id === product.id);
      // if yes add a quatite
      if (foundProduct !== undefined) {
        foundProduct.quantite += 1;
      } else {
        // if not create it
        // eslint-disable-next-line no-param-reassign
        product.quantite = 1;
        this.order.push(product);
      }
    },
    removeFromCart(product: any) {
      // search in order if product is already existing
      const foundProduct = this.order.find((orderProduct) => orderProduct.id === product.id);
      // if yes diminished the quantity
      if (foundProduct !== undefined && foundProduct.quantite > 1) {
        foundProduct.quantite -= 1;
      } else {
        // if not delete it
        this.order = this.order.filter((orderProduct) => orderProduct.id !== product.id);
      }
    },
    confirmOrder() {
      // send axios request
      const bodyParameter = {
        order: this.order,
        note: `Vente direct ${this.libelle}`,
      };

      const configHeader = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      };

      console.log(bodyParameter);
      this.loading = true;

      return axios.post(
        '/ventedirect',
        bodyParameter,
        configHeader,
      ).then((response) => {
        // if request good
        console.log(response);

        // -> reset order array
        this.order = Array<any>();
        // -> send confirmation message
        (this as any).$vaToast.init({ message: 'Commande transmise', color: 'success' });
      })
        .catch((error) => {
          console.log(error);
          (this as any).$vaToast.init({ message: 'Échec', color: 'danger' });
        }).finally(() => { this.loading = false; });
    },
  },
});
