
import { defineComponent } from 'vue';
import axios from 'axios';
import Product from './types/Product';

export default defineComponent({
  name: 'ProductPreview',
  components: {
    // ProduitModal,
  },
  data() {
    const monetaryFormat = new Intl.NumberFormat('fr-CH', {
      style: 'currency',
      currency: 'CHF',

      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers
      // , but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return {
      monetaryFormat,
      focus: false,
      picture: 0,
      quantite: 1,
      newDays: 7,
    };
  },
  computed: {
    isAdmin() {
      return Number(this.$store.getters.user.admin) === 1;
    },
    isPreorder() {
      if (this.product === undefined) {
        return false;
      }
      return this.product.catalog.filter((c) => c.endDate !== undefined).length > 0;
    },
    isAvailableAllYear() {
      if (this.product === undefined) {
        return false;
      }
      return this.product.catalog.filter((c) => c.endDate === undefined).length > 0
        && this.stockValue <= 0;
    },
    isNew() {
      if (this.product === undefined) {
        return false;
      }
      return this.product.createdAt >= new Date(Date.now() - this.newDays * 24 * 60 * 60 * 1000);
    },
    isReliquat() {
      if (this.product === undefined) {
        return false;
      }
      // TODO
      return false;
    },
    stockAmount() {
      if (this.product !== undefined) {
        if (this.product.boxOf > 1) {
          const box = (this.product.available / this.product.boxOf);
          if (box === 1) {
            return `${box} boîte`;
          }
          if (box > 10 && !this.isAdmin) {
            return 'Plus de 10 boîtes';
          }
          return `${box} boîtes`;
        }
        if (this.isAdmin) {
          return this.product.available;
        }
        return this.product.available > 10 ? 'plus de 10' : this.product.available;
      }
      return 0;
    },
    stockValue() {
      if (this.product !== undefined) {
        if (this.product.boxOf > 1) {
          return (this.product.available / this.product.boxOf);
        }
        return this.product.available;
      }
      return 0;
    },
  },
  mounted() {
    if (this.product !== undefined) {
      this.quantite = this.product.boxOf;
    }
  },
  props: {
    product: Object as () => Product,
    filter: String,
  },
  methods: {
    cardFocus() {
      console.log('focus');
    },
    cardColorClass() {
      if (this.product !== undefined) {
        if (this.isPreorder) {
          return 'productPreviewCardPreorder';
        }
        if (this.isAvailableAllYear) {
          return 'productPreviewCardAvailable';
        }
        if (this.stockValue < 3) {
          return 'productPreviewCardVeryLowStock';
        }
        if (this.stockValue < 10) {
          return 'productPreviewCardLowStock';
        }
        return 'productPreviewCardStock';
      }

      return 'productPreviewCardUndefined';
    },
    addToCart() {
      if (this.product === null && this.product === undefined) {
        console.log('product is undefined');
      } else if (this.quantite > 0 && (this.quantite % this.product!.boxOf) !== 0) {
        console.log('La quantité est fausse');
      } else {
        console.log(`adding ${this.quantite} x ${this.product?.variantID} to cart`);
        const requestData = JSON.stringify({
          quantite: this.quantite,
          variante: this.product?.variantID,
          client: this.$store.getters.getSelectedAddress.ID,
        });
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        };

        axios.post('/panier', requestData, config)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              console.log('successfully added to the cart');
              console.log(response);
              this.$store.commit(
                'addMessage',
                {
                  message: 'Produit ajouté au panier',
                  type: 'success',
                },
              );
            }
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit(
              'addMessage',
              {
                message: 'Échec de l\'ajout au panier',
                type: 'danger',
              },
            );
          })
          .finally(() => {
            console.log('success message');
          });
      }
    },
    goToProduct(vId:number) : void {
      this.$router.push({
        name: 'Espace Pro - Detail Produits',
        params: { id: vId },
      });
    },
    badgeText() {
      if (this.isNew) {
        return 'New';
      }
      return '';
    },
    getErrorMessage() : string {
      if (this.product !== null && this.product !== undefined) {
        return `La quantité doit être un multiple de ${this.product.boxOf}`;
      }
      return 'Produit non valide';
    },
  },
});
