
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'AccountSidebar',
  data() {
    return {
    };
  },
  computed: {
    route: () => useRoute(),
    isAdmin() {
      return Number(this.$store.getters.user.admin) === 1;
    },
  },
});
