import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_sidebar_item_title = _resolveComponent("va-sidebar-item-title")!
  const _component_va_sidebar_item_content = _resolveComponent("va-sidebar-item-content")!
  const _component_va_sidebar_item = _resolveComponent("va-sidebar-item")!
  const _component_va_collapse = _resolveComponent("va-collapse")!
  const _component_va_accordion = _resolveComponent("va-accordion")!
  const _component_va_sidebar = _resolveComponent("va-sidebar")!

  return (_openBlock(), _createBlock(_component_va_sidebar, {
    hoverable: "",
    minimizedWidth: "64px",
    class: "sidebar"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_va_sidebar_item, {
        active: _ctx.route.name === 'Espace Pro - Clients',
        to: "/client"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_va_sidebar_item_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_va_icon, { name: "dashboard" }),
              _createVNode(_component_va_sidebar_item_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Vue d'ensemble")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["active"]),
      _createVNode(_component_va_accordion, {
        modelValue: _ctx.accordionValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.accordionValue) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_va_collapse, null, {
            header: _withCtx(() => [
              _createVNode(_component_va_sidebar_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_va_sidebar_item_content, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_va_icon, { name: "add" }),
                      _createVNode(_component_va_sidebar_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Commandes ")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_va_icon, {
                        name: _ctx.accordionValue[1] ? 'expand_less' : 'expand_more'
                      }, null, 8, ["name"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_va_sidebar_item, {
                active: _ctx.route.name === 'Espace Pro - Commandes Clients',
                to: "/client/orders"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_va_sidebar_item_content, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_va_sidebar_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Liste ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["active"]),
              _createVNode(_component_va_sidebar_item, {
                active: _ctx.route.name === 'Espace Pro - Commande Galaxus',
                to: "/client/orders/galaxus"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_va_sidebar_item_content, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_va_sidebar_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Galaxus ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["active"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_va_sidebar_item, {
        active: _ctx.route.name === 'Espace Pro - Livraisons Clients',
        to: "/client/deliveries"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_va_sidebar_item_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_va_icon, { name: "list" }),
              _createVNode(_component_va_sidebar_item_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Livraison")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["active"])
    ]),
    _: 1
  }))
}