
import { defineComponent } from 'vue';
import axios from 'axios';
import Product from './types/Product';
import Catalog from './types/Catalog';
import OrderNumbers from './types/OrderNumbers';
import Address from './types/Address';

export default defineComponent({
  name: 'ProductPreview',
  components: {
    // ProduitModal,
  },
  data() {
    const monetaryFormat = new Intl.NumberFormat('fr-CH', {
      style: 'currency',
      currency: 'CHF',

      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers
      // , but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return {
      loading: false,
      monetaryFormat,
      focus: false,
      product: {} as Product,
      quantite: [],
      stockEdit: false,
      newStock: 0,
      weightEdit: false,
      newWeight: 0,
    };
  },
  computed: {
    isAdmin() {
      return Number(this.$store.getters.user.admin) === 1;
    },
    routeId() {
      return Number(this.$route.params.id);
    },
    addresses(): Array<Address> {
      return this.$store.getters.addresses;
    },
  },
  mounted() {
    if (this.routeId !== undefined) {
      this.getProductData(this.routeId);
    }
  },
  methods: {
    stockValue() {
      if (this.product !== undefined) {
        if (this.product.boxOf > 1) {
          return (this.product.available / this.product.boxOf);
        }
        return this.product.available;
      }
      return 0;
    },
    getProductData(ID:number) {
      this.loading = true;

      axios.get(`/produit/${ID}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200 && response.data !== null) {
            const catalogs = new Array<Catalog>();
            if (response.data.CatalogueIDS !== null) {
              const catalogIDs = response.data.CatalogueIDS.split(';');
              const catalogNames = response.data.CatalogueLibelles.split(';');
              let catalogEndDates = new Array<Date>();
              if (response.data.CatalogeDatesFins !== null) {
                catalogEndDates = response.data.CatalogeDatesFins.split(';');
              }
              for (let index = 0; index < catalogIDs.length; index += 1) {
                const catalog: Catalog = {
                  ID: catalogIDs[index],
                  name: catalogNames[index],
                  endDate: catalogEndDates[index],
                };
                catalogs.push(catalog);
              }
            }
            const orderNumbersArr = new Array<OrderNumbers>();
            if (response.data.adresses !== null) {
              const addressNos = response.data.adresses.split(',');
              const addressOrders = response.data.adresseCommandes.split(',');
              const addressRemainders = response.data.adresseReliquats.split(',');

              for (let index = 0; index < addressNos.length; index += 1) {
                const orderNumber: OrderNumbers = {
                  AdressNo: addressNos[index],
                  OrderQuantity: addressOrders[index],
                  RemainderQuantity: addressRemainders[index],
                };
                orderNumbersArr.push(orderNumber);
              }
            }

            this.product = {
              productID: response.data.pid,
              variantID: response.data.vid,
              releaseDate: new Date(response.data.dateSortie),
              incommingDate: new Date(response.data.dateArrivage),
              license: {
                ID: response.data.licence,
                FR: response.data.LicencesLibelleFR,
                JP: response.data.LicencesLibelleJP,
                EN: response.data.LicencesLibelleEN,
                DE: response.data.LicencesLibelleDE,
                IT: response.data.LicencesLibelleIT,
                master: response.data.LicencesMaitre,
              },
              manufacturer: {
                ID: response.data.fabricant,
                FR: response.data.FabricantsLibelleFR,
                JP: response.data.FabricantsLibelleJP,
              },
              price: response.data.pv,
              msrp: response.data.pvc,
              type: {
                ID: response.data.type,
                FR: response.data.TypesLibelleFR,
                JP: response.data.TypesLibelleJP,
                EN: response.data.TypesLibelleEN,
                DE: response.data.TypesLibelleIT,
                IT: response.data.TypesLibelleIT,
                master: response.data.TypesMaitre,
              },
              boxOf: response.data.boiteDe,
              packingSize: response.data.colisage,
              ean: response.data.ean,
              weight: response.data.poids,
              characters: response.data.Personnages !== null ? response.data.Personnages.split(',') : undefined,
              productPictures: response.data.PhotosProduit !== null ? response.data.PhotosProduit.split(',') : undefined,
              variantPictures: response.data.PhotosVariante !== null ? response.data.PhotosVariante.split(',') : undefined,
              nameFR: response.data.NomFR,
              nameEN: response.data.NomEN,
              nameDE: response.data.NomDE,
              nameIT: response.data.NomIT,
              catalog: catalogs,
              order: orderNumbersArr,
              available: response.data.disponiblePositif,
              createdAt: new Date(response.data.VarianteCreatedAt),
              updatedAt: new Date(response.data.VarianteUpdatedAt),
            };
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getStockAmount() {
      if (this.product !== undefined) {
        if (this.product.boxOf > 1) {
          const box = (this.product.available / this.product.boxOf);
          if (box === 1) {
            return `${box} boîte`;
          }
          if (box > 10 && !this.isAdmin) {
            return 'Plus de 10 boîtes';
          }
          return `${box} boîtes`;
        }
        if (this.isAdmin) {
          return this.product.available;
        }
        return this.product.available > 10 ? 'plus de 10' : this.product.available;
      }
      return 0;
    },
    getAddressName(no:number) {
      const address = this.getAddress(no);
      if (address !== undefined) {
        return address.name;
      }
      this.$store.dispatch('updateState');
      return `${no} non trouvé`;
    },
    getAddress(no:number) {
      return this.$store.getters.getAddressForId(no);
    },
    addToCart(no:number, qty: number) {
      if (this.product === null && this.product === undefined) {
        console.log('product is undefined');
      } else if (qty > 0 && (qty % this.product!.boxOf) !== 0) {
        console.log('La quantité est fausse');
      } else {
        this.loading = true;
        console.log(`adding ${qty} x ${this.product.variantID} to cart`);
        const requestData = JSON.stringify({
          quantite: qty,
          variante: this.product.variantID,
          client: no,
        });
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        };

        axios.post('/panier', requestData, config)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.$store.commit(
                'addMessage',
                {
                  message: 'Produit ajouté au panier',
                  type: 'success',
                },
              );
            }
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit(
              'addMessage',
              {
                message: 'Échec de l\'ajout au panier',
                type: 'danger',
              },
            );
          })
          .finally(() => {
            console.log('success message');
            this.loading = false;
          });
      }
    },
    stockColorClass() {
      if (this.product !== undefined) {
        if (this.product.catalog !== undefined && this.product.catalog.length > 0) {
          return 'info';
        }
        if (this.stockValue() < 3) {
          return 'danger';
        }
        if (this.stockValue() < 10) {
          return 'warning';
        }
        return 'success';
      }

      return '#000000';
    },
    str2bytes(str:string) {
      const bytes = new Uint8Array(str.length);
      for (let i = 0; i < str.length; i += 1) {
        bytes[i] = str.charCodeAt(i);
      }
      return bytes;
    },
    downloadPicturesAsZip() {
      axios.get(`/downloadaszip/images/${this.routeId}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
        responseType: 'arraybuffer',
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: 'application/octet-stream',
          });
          const fileURL = window.URL.createObjectURL(blob);
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'images.zip');
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getErrorMessage() : string {
      if (this.product !== null && this.product !== undefined) {
        return `La quantité doit être un multiple de ${this.product.boxOf}`;
      }
      return 'Produit non valide';
    },
    editStock() : void {
      this.newStock = this.product.available;
      this.stockEdit = true;
    },
    doneEditStock() : void {
      // update value then update product then edit to false
      this.loading = true;

      const requestData = JSON.stringify({
        stock: this.newStock,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      };

      axios.put(`/inventaire/${this.product.variantID}`, requestData, config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log(response);
            this.$store.commit(
              'addMessage',
              {
                message: 'Stock modifié',
                type: 'success',
              },
            );
          }
          if (this.routeId !== undefined) {
            this.getProductData(this.routeId);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addMessage',
            {
              message: 'Échec de la modification',
              type: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
          this.stockEdit = false;
        });
    },
    editWeight() : void {
      this.newWeight = this.product.weight;
      this.weightEdit = true;
    },
    doneEditWeight() : void {
      // update value then update product then edit to false
      this.loading = true;

      const requestData = JSON.stringify({
        poids: this.newWeight,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      };

      axios.put(`/variante/${this.product.variantID}`, requestData, config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log(response);
            this.$store.commit(
              'addMessage',
              {
                message: 'Poids modifié',
                type: 'success',
              },
            );
          }
          if (this.routeId !== undefined) {
            this.getProductData(this.routeId);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addMessage',
            {
              message: 'Échec de la modification',
              type: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
          this.weightEdit = false;
        });
    },
    async copyToClipboard(value: string) {
      try {
        await navigator.clipboard.writeText(value);
        this.$store.commit(
          'addMessage',
          {
            message: 'Copié',
            type: 'success',
          },
        );
      } catch ($e) {
        this.$store.commit(
          'addMessage',
          {
            message: 'Echec de l\'action',
            type: 'danger',
          },
        );
      }
    },
  },
});
