
import AccountSidebar from '@/components/AccountSidebar.vue';
import axios from 'axios';
import { defineComponent } from 'vue';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'vue-chartjs';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export default defineComponent({
  name: 'AdminDashboard',
  data() {
    const lineOptions = {
      responsive: true,
      maintainAspectRatio: false,
    };

    const testData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Data One',
          backgroundColor: '#f87979',
          borderColor: 'rgb(255, 99, 132)',
          data: [40, 39, 10, 40, 39, 80, 40],
        },
      ],
    };

    const precisionOptions = [
      { label: 'Mois', value: 'month' },
      { label: 'Semaine', value: 'week' },
      { label: 'Jours', value: 'day' },
    ];

    const monetaryFormat = new Intl.NumberFormat('fr-CH', {
      style: 'currency',
      currency: 'CHF',
    });

    return {
      loading: true,
      today: new Date(),
      startDate: new Date(2022, 9, 2),
      sliderDayValue: 0,
      lineOptions,
      chartData: testData,
      detailledData: {
        stockValue: 0,
        remainderValue: 0,
        supplierValue: 0,
        deliveryValue: 0,
        clientOrderValue: 0,
        clientInvoiceValue: 0,
        incommingValue: 0,
      },
      precisionOptions,
      precisionValue: precisionOptions[0].value,
      monetaryFormat,
    };
  },
  components: {
    AccountSidebar,
    Line,
  },
  computed: {

  },
  watch: {
    // whenever question changes, this function will run
    precisionValue(newValue) {
      if (newValue === this.precisionOptions[0].value) {
        // monthly
        console.log('monthly');
        const months = this.monthsBetween(this.startDate, this.today);
        this.getChartData(months);
      } else if (newValue === this.precisionOptions[1].value) {
        // Weekly
        console.log('weekly');
        const weeks = this.weeksBetween(this.startDate, this.today);
        this.getChartData(weeks);
      } else {
        // daily
        console.log('daily');
        const days = this.daysDatesBetween(this.startDate, this.today);
        this.getChartData(days);
      }
    },
  },
  mounted() {
    this.sliderDayValue = this.daysBetween(this.startDate, this.today);
    const months = this.monthsBetween(this.startDate, this.today);
    this.getChartData(months);
    this.getData(this.today);
  },
  methods: {
    getChartData(dates: Date[]): void {
      this.loading = true;

      const getFunction = (url:string) => axios.get(url, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      });

      if (dates === undefined || dates.length === 0) {
        return;
      }
      const promiseArray = dates
        .map((date) => getFunction(`/etatstock/date/${this.printDate(date)}`));

      Promise.all(promiseArray)
        .then((data) => {
          const label = data.map((d) => d.data.date);
          const stockData = data.map((d) => d.data.resume.stockValue);
          const clientOrder = data.map((d) => d.data.resume.clientOrderValue);

          console.log(label as string[]);
          console.log(stockData);

          this.chartData = {
            labels: label,
            datasets: [
              {
                label: 'Valeur du stock',
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132,0.5)',
                data: stockData,
              },
              {
                label: 'Valeur des commandes clients',
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235,0.5)',
                data: clientOrder,
              },
            ],
          };
        }).catch((error) => {
          console.log(error);
          this.$store.commit(
            'addMessage',
            {
              message: 'Échec de la récupération des données',
              type: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getData(date: Date) {
      axios.get(`/etatstock/date/${this.printDate(date)}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      }).then((d) => {
        this.detailledData.stockValue = d.data.resume.stockValue;
        this.detailledData.remainderValue = d.data.resume.remainderValue;
        this.detailledData.supplierValue = d.data.resume.supplierValue;
        this.detailledData.deliveryValue = d.data.resume.deliveryValue;
        this.detailledData.clientOrderValue = d.data.resume.clientOrderValue;
        this.detailledData.clientInvoiceValue = d.data.resume.clientInvoiceValue;
        this.detailledData.incommingValue = d.data.resume.incommingValue;
      }).catch((error) => {
        console.log(error);
        this.$store.commit(
          'addMessage',
          {
            message: 'Échec de la récupération des données',
            type: 'danger',
          },
        );
      });
    },
    printDate(date: Date): string {
      return date.toISOString().split('T')[0];
    },
    daysBetween(d1: Date, d2: Date) : number {
      const diff = Math.abs(d1.getTime() - d2.getTime());
      return Math.ceil(diff / (1000 * 3600 * 24));
    },
    datePlusDay(start:Date, days: number) : Date {
      const date = new Date(start.getTime());
      date.setDate(date.getDate() + days);
      return date;
    },
    daysDatesBetween(d1: Date, d2: Date) : Date[] {
      const dates = new Array<Date>();

      const endDate = new Date(d2.getFullYear(), d2.getMonth(), 1);
      let indexDate = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());

      while (indexDate.getTime() < endDate.getTime()) {
        dates.push(indexDate);
        indexDate = new Date(indexDate.setDate(indexDate.getDate() + 1));
      }

      return dates;
    },
    weeksBetween(d1: Date, d2: Date) : Date[] {
      const dates = new Array<Date>();

      const endDate = new Date(d2.getFullYear(), d2.getMonth(), 1);
      let indexDate = new Date(d1.getFullYear(), d1.getMonth(), 1);

      while (indexDate.getTime() < endDate.getTime()) {
        dates.push(indexDate);
        indexDate = new Date(indexDate.setDate(indexDate.getDate() + 7));
      }

      return dates;
    },
    monthsBetween(d1: Date, d2: Date) : Date[] {
      const dates = new Array<Date>();

      const endDate = new Date(d2.getFullYear(), d2.getMonth(), 1);
      let indexDate = new Date(d1.getFullYear(), d1.getMonth(), 1);

      while (indexDate.getTime() < endDate.getTime()) {
        dates.push(this.lastDayofMonth(indexDate));
        indexDate = new Date(indexDate.setMonth(indexDate.getMonth() + 1));
      }

      return dates;
    },
    lastDayofMonth(date: Date) : Date {
      return this.getLastDayOfMonth(
        date.getFullYear(),
        date.getMonth(),
      );
    },
    getLastDayOfMonth(year: number, month: number): Date {
      return new Date(year, month + 1, 1);
    },
  },
});
