<template>
  <div class="proLogin">
    <LoginForm ref="Login" class="loginForm"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import LoginForm from '@/components/LoginForm.vue'; // @ is an alias to /src

const data = {
};

export default defineComponent({
  name: 'LoginView',
  data() {
    return data;
  },
  components: {
    LoginForm,
  },
});
</script>

<style>
.proLogin{
  display: flex;
  min-height: 80vh;
}

.loginForm {
  margin: auto;
  max-width: 400px;
}
</style>
