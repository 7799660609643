import State from './State';

export default class TypeUtil {
  public static parseState(st: number): State {
    switch (st) {
      case 1: return State.DRAFT;
      case 2: return State.PUBLIC;
      case 3: return State.DRAFT;
      default: return State.UNKNOWN;
    }
  }
}
