<template>
  <div class="contact">
    <h1>Contactez-nous</h1>
    <div id="contactForm">
      <form ref="form" @submit.prevent="sendEmail">
        <va-form>
          <div class="row">
            <div class="flex md6">
              <va-input
                class="mb-4 mr-4"
                label="Prénom | First Name | お名"
                v-model="form.contactFirstName"
                name="contactFirstName"
                id="contactFirstName"
                :rules="[value => (value && value.length > 0) || 'Field is required']"
              />
            </div>
            <div class="flex md6">
              <va-input
                class="mb-4 mr-4"
                label="Nom de famille | Last Name | お姓"
                v-model="form.contactLastName"
                name="contactLastName"
                id="contactLastName"
                :rules="[value => (value && value.length > 0) || 'Field is required']"
              />
            </div>
            <div class="flex md6">
              <va-input
                class="mb-4 mr-4"
                label="Email | おメールアドレス"
                type="email"
                v-model="form.contactEmail"
                name="contactEmail"
                id="contactEmail"
                :rules="[value => (value && value.length > 0) || 'Field is required']"
              />
            </div>
            <div class="flex md6">
              <va-input
                class="mb-4 mr-4"
                label="Entreprise | Company | お貴社"
                v-model="form.contactCompany"
                name="contactCompany"
                id="contactCompany"
              />
            </div>
          </div>
          <div id="contactFormMessage">
            <va-input
              class="mb-4 mr-4"
              label="Message | お問い合わせ内容"
              type="textarea"
              v-model="form.contactMessage"
              name="contactMessage"
              id="contactMessage"
              :rules="[value => (value && value.length > 0) || 'Field is required']"
            />
          </div>
          <vue-recaptcha sitekey="6LeyUsYhAAAAAKCrLWLpBXNc17B0WadZ4gJwHF34">
            <va-button
              class="mb-4 mr-4"
              @click="sendEmail()"
              :loading="loading"
            >
              Envoyer | Submit | 送信する
            </va-button>
          </vue-recaptcha>
        </va-form>
      </form>
      <va-alert v-if="success" color="success" class="mb-4">
        Nous avons bien reçu votre message et nous vous recontacterons dans les plus brefs délais.
        <br/>
        Your message has been sent successfully. We will contact you shortly.
        <br/>
      </va-alert>
      <va-alert v-if="error" color="danger" class="mb-4">
        Il y a eu un problème avec l'envoi de votre message. Merci de réessayer plus tard.
      </va-alert>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import emailjs from 'emailjs-com';

const data = {
  form: {
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactMessage: '',
    contactCompany: '',
  },
  loading: false,
  success: false,
  error: false,
};

export default defineComponent({
  name: 'ContactView',
  data() {
    return data;
  },
  methods: {
    sendEmail() {
      console.log(this.form);
      this.loading = true;
      emailjs.sendForm('service_x0w3psm', 'template_fyhiqip', this.$refs.form, 'user_D6t519Pihy9kLrC8BHeUC')
        .then((result) => {
          console.log('SUCCESS!', result.text);
          this.success = true;
          this.error = false;
          this.loading = false;
          this.clearForm();
        }, (error) => {
          console.log('FAILED...', error.text);
          this.error = true;
          this.success = false;
          this.loading = false;
        });
    },
    clearForm() {
      this.formcontactFirstName = '';
      this.formcontactLastName = '';
      this.formcontactEmail = '';
      this.formcontactMessage = '';
      this.formcontactCompany = '';
    },
  },
});
</script>

<style>
#contactForm {
  margin: auto;
  max-width: 800px;
  padding-top:20px;
}
</style>
