
import { defineComponent } from 'vue';
import axios from 'axios';
import leven from 'leven';
import { concat } from 'lodash';
import License from './types/License';
import Manufacturer from './types/Manufacturer';
import Type from './types/Type';
import Character from './types/Character';

export default defineComponent({
  name: 'MigrationList',
  components: {
  },
  data() {
    return {
      priceSuggestion: {
        currencyRate: 120,
        importMargin: 0.4,
        defaultSellMargin: 0.25,
        defaultClientMargin: 0.4,
        VAT: 0.077,
      },
      oldProductTab: false,
      oldProductId: -1,
      feedProductTab: false,
      feedHLJProductSKU: '',
      feedMusuviProductSKU: '',
      newProductLastId: 0,
      oldProduct: '',
      newTypes: Array<Type>(),
      newLicenses: new Array<License>(),
      newCharacters: new Array<Character>(),
      newManufacturers: new Array<Manufacturer>(),
      newProvider: [],
      newProductData: {
        reference: '',
        nomFournisseur: '',
        fournisseur: '',
        colisage: '',
        boiteDe: 1,
        prixJPY: 0,
        rabaisJPY: 0,
        pa: 0,
        pv: 0,
        pvc: 0,
        dateSortie: '2022-01-01T22:00:00.000Z',
        dateArrivage: '2022-01-01T22:00:00.000Z',
        licence: {} as License,
        fabricant: {} as Manufacturer,
        type: {} as Type,
      },
      varianteData: Array<any>(),
      nbVariante: 1,
      pictures: [],
      url: '',
      urlBase64: '',
      pictureMode: false,
      exist: false,
      loading: false,
      success: false,
      error: false,
    };
  },
  watch: {
    oldProductId: {
      handler(newId) {
        // this will be run immediately on component creation.
        this.updateOldProduct(newId);
      },
      // force eager callback execution
      immediate: false,
    },
    feedHLJProductSKU: {
      handler(sku) {
        // this will be run immediately on component creation.
        this.feedHLJProduct(sku);
      },
      // force eager callback execution
      immediate: false,
    },
    feedMusuviProductSKU: {
      handler(sku) {
        // this will be run immediately on component creation.
        this.feedMusuviProduct(sku);
      },
      // force eager callback execution
      immediate: false,
    },
  },
  created() {
    this.updateSelects();
  },
  methods: {
    updateOldProduct(id:number) {
      if (id < 0) {
        return;
      }
      console.log(`/migration/oldproduct/${id}`);
      this.loading = true;
      this.error = false;
      this.success = false;
      axios.get(`/migration/oldproduct/${id}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      }).then((response) => {
        console.log(response);
        this.oldProduct = response.data;
        console.log(this.oldProduct);
        // Check if the product is already created
        axios.get(`/migration/newProductIDexist/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        }).then((responseNewID) => {
          if (responseNewID.data !== null) {
            this.exist = true;
            this.copyData(responseNewID.data);
          } else {
            this.copyData(response.data);
            this.exist = false;
          }
        });
      }).finally(() => { this.loading = false; });
    },
    feedHLJProduct(sku: string) {
      if (sku.length < 8) {
        return;
      }
      this.loading = true;
      this.error = false;
      this.success = false;
      axios.get(`/feed/HLJ/${sku}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      }).then((response) => {
        console.log(response);
        this.feedData(response.data);
        // TODO pass varianteData as type
      }).finally(() => { this.loading = false; });
    },
    feedMusuviProduct(sku:string) {
      if (sku.length < 3) {
        return;
      }
      this.loading = true;
      this.error = false;
      this.success = false;
      axios.get(`/feed/MUSUVI/${sku}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      }).then((response) => {
        console.log(response);
        this.feedData(response.data);
        // TODO pass varianteData as type
      }).finally(() => { this.loading = false; });
    },
    feedData(data:any) {
      this.newProductData.reference = data.product.SKU;
      this.newProductData.nomFournisseur = data.product.NOM_FOURNISSEUR;

      const searchedSupplier = data.product.FOURNISSEUR;
      this.newProductData.fournisseur = String(this.newProvider.map(
        // eslint-disable-next-line max-len
        (pro: { libelle: string; id: number}) => ({ pro, distance: leven(pro.libelle, searchedSupplier) }),
      ).sort((a, b) => a.distance - b.distance)[0].pro.id);

      this.newProductData.colisage = data.product.COLISAGE;
      this.newProductData.prixJPY = data.product.PRIX_JPY;
      this.newProductData.rabaisJPY = data.product.RABAIS_PRIX_JPY;
      this.newProductData.dateSortie = data.product.DATE_SORTIE;
      this.newProductData.dateArrivage = data.product.DATE_ARRIVAGE;

      const searchedLicence = data.product.LICENCE;
      this.newProductData.licence = this.newLicenses.map(
        // eslint-disable-next-line max-len
        (lic:License) => ({ lic, distance: leven(lic.EN !== null ? lic.EN : lic.FR, searchedLicence) }),
      ).sort((a, b) => a.distance - b.distance)[0].lic;

      const searchedManufacturer = data.product.FABRICANT;
      this.newProductData.fabricant = this.newManufacturers.map(
        // eslint-disable-next-line max-len
        (man:Manufacturer) => ({ man, distance: leven(man.FR, searchedManufacturer) }),
      ).sort((a, b) => a.distance - b.distance)[0].man;

      const searchedType = data.product.TYPE;
      this.newProductData.type = this.newTypes.map(
        // eslint-disable-next-line max-len
        (typ:Type) => ({ typ, distance: leven(typ.EN !== null ? typ.EN : typ.FR, searchedType) }),
      ).sort((a, b) => a.distance - b.distance)[0].typ;

      // add variante
      this.clearVariante();
      this.addVariante();

      // TODO variante Data weight if any
      this.varianteData[0].ean = data.product.EAN;
      this.varianteData[0].colisage = data.product.COLISAGE;
      this.varianteData[0].poids = 100;
      this.varianteData[0].hiddenPictures = data.product.IMAGES_B64;
    },
    addVariante() {
      const obj = {
        nom: '',
        ean: '',
        poids: '',
        colisage: '',
        personnages: [],
        pictures: Array<any>(),
        hiddenPictures: Array<any>(),
      };
      this.varianteData.push(obj);
    },
    removeVariante(counter:number) {
      this.varianteData.splice(counter, 1);
    },
    clearVariante() {
      this.varianteData = Array<any>();
    },
    updateSelects() {
      this.getOptionsData();
      this.getNewProviders();
    },
    getOptionsData() {
      this.loading = true;

      const fetchFunction = (url:string) => axios.get(url, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      });

      const URLLicenses = '/licence';
      const URLTypes = '/type';
      const URLManufacturers = '/fabricant';
      const URLCharacters = '/personnagelicence';

      const promiseArray = [URLLicenses, URLTypes, URLManufacturers, URLCharacters]
        .map(fetchFunction);

      Promise.all(promiseArray)
        .then((data) => {
          this.newLicenses = data[0].data.map((d:any) => ({
            ID: d.id,
            FR: d.libelleFR,
            JP: d.libelleJP,
            EN: d.libelleEN,
            DE: d.libelleDE,
            IT: d.libelleIT,
            master: d.maitreLicence,
          }));
          this.newTypes = data[1].data.map((d:any) => ({
            ID: d.id,
            FR: d.libelleFR,
            JP: d.libelleJP,
            EN: d.libelleEN,
            DE: d.libelleDE,
            IT: d.libelleIT,
            master: d.maitre,
          }));
          this.newManufacturers = data[2].data.map((d:any) => ({
            ID: d.id,
            FR: d.libelleFR,
            JP: d.libelleJP,
          }));
          this.newCharacters = data[3].data.map((d:any) => ({
            ID: d.id,
            FR: d.libelleFR,
            JP: d.libelleJP,
            EN: d.libelleEN,
            DE: d.libelleDE,
            IT: d.libelleIT,
            licence: d.licence,
          }));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => { this.loading = false; });
    },
    getNewProviders() {
      // get the new database types
      axios.get('/migration/newproviders', {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      }).then((response) => {
        this.newProvider = response.data;
      });
    },
    generateBarcode(id: number):string {
      const start = '299';
      const supplierCode = this.getSupplierCode(this.newProductData.fabricant.FR);
      const idNumber = String(id + 1).padStart(2, '0');
      // 6 last number of sku
      const ref = this.newProductData.reference.replace(/\D/g, '').slice(-6).padStart(6, '0');

      const lastDigit = this.eanLastDigit(`${start}${supplierCode}${idNumber}${ref}`);

      return `${start}${supplierCode}${idNumber}${ref}${lastDigit}`;
    },
    eanLastDigit(s:string) {
      let result = 0;
      let i = 1;
      for (let counter = s.length - 1; counter >= 0; counter -= 1) {
        result += parseInt(s.charAt(counter), 10) * (1 + (2 * (i % 2)));
        i += 1;
      }
      return (10 - (result % 10)) % 10;
    },
    getSupplierCode(name:string):string {
      switch (name.toUpperCase()) {
        case 'BANPRESTO':
          return '2';
        case 'FURYU':
          return '1';
        case 'EIKOH':
          return '3';
        case 'TAITO':
          return '4';
        case 'SYSTEM':
          return '5';
        default: return '0';
      }
    },
    copyData(data:any) {
      // Variante
      if (this.varianteData.length < 1) {
        this.addVariante();
      }
      this.varianteData[0].ean = data.ean;

      // Product
      this.newProductData.reference = data.reference;
      this.newProductData.colisage = data.colisage;
      this.newProductData.pa = data.pa;
      this.newProductData.pv = data.pv;
      this.newProductData.pvc = data.pvc;
      this.newProductData.boiteDe = 1;

      // eslint-disable-next-line max-len
      const indexP = this.newProvider.findIndex((provider:any) => provider.libelle.toLowerCase() === data.fournisseur.toLowerCase());
      // eslint-disable-next-line prefer-destructuring
      this.newProductData.fournisseur = this.newProvider[indexP];

      // eslint-disable-next-line max-len
      const indexM = this.newManufacturers.findIndex((manufacturer:any) => manufacturer.FR.toLowerCase() === data.marque.toLowerCase());
      // eslint-disable-next-line prefer-destructuring
      this.newProductData.fabricant = this.newManufacturers[indexM];

      // eslint-disable-next-line max-len
      const indexL = this.newLicenses.findIndex((license:any) => license.libelleFR.toLowerCase() === data.nom.split('-')[0].trim().toLowerCase());
      // eslint-disable-next-line prefer-destructuring
      this.newProductData.licence = this.newLicenses[indexL];

      // eslint-disable-next-line max-len
      const indexT = this.newTypes.findIndex((type:any) => type.libelleFR.toLowerCase() === data.nom.split('-')[1].trim().toLowerCase());
      // eslint-disable-next-line prefer-destructuring
      this.newProductData.type = this.newTypes[indexT];

      // TODO check boite de
    },
    saveNewProduct() {
      console.log('save');
      this.loading = true;
      const myDateArrivage = new Date(this.newProductData.dateArrivage);
      const offsetArrivage = myDateArrivage.getTimezoneOffset();
      const finalDateArrivage = new Date(myDateArrivage.getTime() - (offsetArrivage * 60 * 1000)).toISOString().split('T')[0];
      const myDateSortie = new Date(this.newProductData.dateSortie);
      const offsetSortie = myDateSortie.getTimezoneOffset();
      const finalDateSortie = new Date(myDateSortie.getTime() - (offsetSortie * 60 * 1000)).toISOString().split('T')[0];

      Promise.all(Array.prototype.map.call(this.varianteData, this.readFiles))
        .then((variantes:any[]) => {
          // move body parameters and post request here
          if (this.oldProductTab) {
            const bodyParameter = {
              oldProduct: this.oldProduct,
              newProduct: {
                reference: this.newProductData.reference,
                nomFournisseur: this.newProductData.nomFournisseur,
                fournisseur: this.newProductData.fournisseur,
                colisage: this.newProductData.colisage,
                boiteDe: this.newProductData.boiteDe,
                prixJPY: this.newProductData.prixJPY,
                rabaisJPY: this.newProductData.rabaisJPY,
                pa: this.newProductData.pa,
                pv: this.newProductData.pv,
                pvc: this.newProductData.pvc,
                dateSortie: this.newProductData.dateSortie,
                dateArrivage: this.newProductData.dateArrivage,
                licence: this.newProductData.licence.ID,
                fabricant: this.newProductData.fabricant.ID,
                type: this.newProductData.type.ID,
              },
              dateArrivage: finalDateArrivage,
              dateSortie: finalDateSortie,
              variants: this.varianteData,
              variantesPictures: variantes,
            };

            const configHeader = {
              headers: {
                Authorization: `Bearer ${this.$store.getters.token}`,
              },
            };

            console.log(bodyParameter);

            return axios.post(
              '/migration/newproduct',
              bodyParameter,
              configHeader,
            )
              .then((response) => {
                console.log(response);
                // go to the next product
                this.success = true;
                this.nextProduct();
              })
              .catch((error) => {
                this.error = true;
                if (error.response) {
                // Request made and server responded
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                // The request was made but no response was received
                  console.log(error.request);
                } else {
                // Something happened in setting up the request that triggered an Error
                  console.log('Error', error.message);
                }
              });
          }

          const picturesData = variantes.map(
            (readData:any[], index) => readData.concat(this.varianteData[index].hiddenPictures),
          );

          // TODO add hidden pictures[0] to variantes[0]
          const bodyParameter = {
            newProduct: {
              reference: this.newProductData.reference,
              nomFournisseur: this.newProductData.nomFournisseur,
              fournisseur: this.newProductData.fournisseur,
              colisage: this.newProductData.colisage,
              boiteDe: this.newProductData.boiteDe,
              prixJPY: this.newProductData.prixJPY,
              rabaisJPY: this.newProductData.rabaisJPY,
              pa: this.newProductData.pa,
              pv: this.newProductData.pv,
              pvc: this.newProductData.pvc,
              dateSortie: this.newProductData.dateSortie,
              dateArrivage: this.newProductData.dateArrivage,
              licence: this.newProductData.licence.ID,
              fabricant: this.newProductData.fabricant.ID,
              type: this.newProductData.type.ID,
            },
            dateArrivage: finalDateArrivage,
            dateSortie: finalDateSortie,
            variants: this.varianteData,
            variantesPictures: picturesData,
          };

          const configHeader = {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          };

          console.log(bodyParameter);

          return axios.post(
            '/produit',
            bodyParameter,
            configHeader,
          )
            .then((response) => {
              console.log(response);
              // go to the next product
              this.success = true;
              this.nextProduct();
            })
            .catch((error) => {
              this.error = true;
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
            });
        })
        .catch((error) => {
          this.error = true;
          // ...handle/report error...
          console.log(error);
        })
        .finally(() => { this.loading = false; });
    },
    readFiles(variante: {[pictures: string]: any}) {
      console.log(variante);
      return Promise.all(variante.pictures.map((picture:any) => this.readFile(picture)));
      /* return variantes.map((variante) =>
      variante.pictures.map((picture:any) => this.readFiles(picture))); */
    },
    readFile(file:any) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = () => {
          resolve(fr.result);
        };
        fr.onerror = reject;
        fr.readAsDataURL(file);
      });
    },
    nextProduct() {
      this.varianteData = Array<any>();
      console.log('go to next product');
    },
    switchOldProductTab() {
      this.oldProductTab = !this.oldProductTab;
      this.feedProductTab = false;
      console.log(this.oldProduct);
    },
    switchFeedProductTab() {
      this.feedProductTab = !this.feedProductTab;
      this.oldProductTab = false;
    },
    getPA():number {
      if (this.newProductData.rabaisJPY !== 0) {
        return this.round2decimal(
          (this.newProductData.rabaisJPY * (1 + this.priceSuggestion.importMargin))
          / this.priceSuggestion.currencyRate,
        );
      }

      return this.round2decimal(this.newProductData.prixJPY / this.priceSuggestion.currencyRate);
    },
    getPV(): number {
      const importMargin = this.newProductData.pa
        - (this.newProductData.pa / (1 + this.priceSuggestion.importMargin));
      const priceWithoutImportMargin = (this.newProductData.pa - importMargin)
       / (1 - this.priceSuggestion.defaultSellMargin);
      return this.round2decimal(
        priceWithoutImportMargin + importMargin,
      );
    },
    getPVC(): number {
      const price = this.newProductData.pv / (1 - this.priceSuggestion.defaultClientMargin);
      const withVAT = price * (1 + this.priceSuggestion.VAT);
      const niceRound = Math.ceil(withVAT) - 0.1;
      return this.round2decimal(niceRound);
    },
    round2decimal(num:number):number {
      return Math.round((num + Number.EPSILON) * 100) / 100;
    },
  },
});
