<template>
  <div class="CG CGFr">
    <h1 class="cgTitle">Conditions générales Japort SA</h1>
    <p class="cgTitle">
      Les présentes conditions générales sont valables dès le 1er décembre 2021.
      Dernier mis à jour: <i>Mars 2023</i>.
    </p>
    <h2 class="cgSubTitle">1. Clients</h2>
    <p>Pour devenir clients, il faut être inscrit au registre du commerce,
       avec un but correspondant à la revente de nos marchandises.</p>
    <h2 class="cgSubTitle">2. Commandes</h2>
    <p>
      Toute commande est réputées acceptée par les deux parties,
      après un délai de 7 jours, dès reception de la confirmation de commande.
      Le paiement de la marchandises en stock est dû, passé ce délai.
      Toute marchandise reste la propriété de Japort SA
      jusqu’à ce qu’elle soit payée dans son intégralité.
    </p>
    <h2 class="cgSubTitle">3. Pré-commandes</h2>
    <p>
      Toute pré-commande doit être payée à 50% de la valeur des marchandises pré-commandées,
      au moment de la commande, pour être validée.
      Le montant fait office de garantie contre les pré-commandes et les annulations abusives.
      Ainsi, ce montant ne peut être remboursé, en aucune façon.
      Le solde du paiement est dû une fois que la marchandise est en stock.
    </p>
    <h2 class="cgSubTitle">4. Livraisons</h2>
    <p>
      Toute commande est livrée gratuitement dès 400.00 CHF, hors TVA.
      Dans le cas où votre commande n’atteint pas 400.00 CHF,
      hors TVA, et sans avis contraire de votre part,
      nous gardons la marchandise deux semaines au maximum,
      pour vous permettre d’atteindre 400.00 CHF, hors TVA.
      Passé ce délai, ou si vous nous en faite la demande,
      nous faisons partir votre marchandise contre le paiement des frais d’envoi et d’emballage,
      à réception du colis, pour un montant de 30.00 CHF, hors TVA, par colis.
      Une fois que la marchandise quitte notre société,
      la responsabilité de celle-ci vous est transmise.
    </p>
    <h2 class="cgSubTitle">5. Annulations</h2>
    <p>
      Toute annulation due aux fabricants vous est transmise, dès que nous sommes mis au courant.
      Dans le cas où vous avez déjà payé tout ou une partie de la marchandise annulée,
      une note de crédit vous est déduite sur votre prochaine facture.
      Tout dommages ne peut nous être réclamé.
    </p>
    <h2 class="cgSubTitle">6. Retours</h2>
    <p>
      Tout retour est strictement exceptionnel, et à vos frais.
      Une demande par mail doit être faite avant tout renvoi de marchandises,
      nous expliquant pourquoi vous voulez faire un retour.
    </p>
    <h2 class="cgSubTitle">7. Dépôt-vente</h2>
    <p>
      Lors d'évenemnt ponctuels, nous offrons la possibilité à nos clients de faire
      un dépôt-vente. Ce dernier est soumis aux conditions suivantes.
    </p>
      <h3 class="cgSubSubTitle">7.1 Définition</h3>
        Un dépôt-vente est la mise à disposition de marchandise
         à un client lors d'un évenement ponctuel.
        Le client pourra retourner la quantité de marchandise désirée à la fin du dépôt-vente.
        Le client sera facturé à la fin du dépôt vente.
        La facture comprendra la marchandise vendue lors de l'évenement,
        les éventuels frais de traitement de retours, ainsi que d'éventuels frais de retard.
      <h3 class="cgSubSubTitle">7.2 Minimum de dépôt-vente</h3>
        Le montant minimum de dépôt est de <strong>400.00 CHF</strong>, hors TVA.
        Aucun dépôt-vente ne sera fait en dessous de ce montant.
      <h3 class="cgSubSubTitle">7.3 Livraison</h3>
        Les frais de livraison de Japort SA au client est pris en charge par Japort SA.
        Il est possible, sur demande du client,
         de livrer directement la marchandise sur le lieu de l'évenement.
      <h3 class="cgSubSubTitle">7.4 Condition de retour de la marchandise</h3>
        <p>La marchandise doit être retournée intacte,
        sans étiquette ou tout autre élément non présent lors de la livraison.</p>
        <p>La responsabilité du retour et les éventuels
           frais de retour sont à la charge du client.</p>
        <p>Tout défaut constaté entraînera le refus du retour de la marchandise.
          Les frais de traitement de retour s'appliqueront aux refus.
        </p>
      <h3 class="cgSubSubTitle">7.5 Frais de traitement de retour</h3>
        <p>En fonction de la valeur retournée,
         un pourcentage sera prélevée à titre de frais de traitement du retour.</p>
        <ul>
          <li>00% à 25% de la valeur total du dépôt-vente retournée:
            <strong>5%</strong> de frais de traitement (Hors TVA)</li>
          <li>26% à 50% de la valeur total du dépôt-vente retournée:
            <strong>10%</strong> de frais de traitement (Hors TVA)</li>
          <li>51% à 75% de la valeur total du dépôt-vente retournée:
            <strong>15%</strong> de frais de traitement (Hors TVA)</li>
          <li>76% et plus de la valeur total du dépôt-vente retournée:
            <strong>20%</strong> de frais de traitement (Hors TVA)</li>
        </ul>
      <h3 class="cgSubSubTitle">7.6 Durée du dépôt-vente</h3>
        <p>La durée du dépot est de <strong>5 jours</strong> au maximum.</p>
        <p>Tout dépassement de la durée de dépôt-vente entrainera
         des frais de retard équivalent à 1% de la valeur (Hors TVA)
          total du dépôt-vente par jour de retard.
        </p>
        <p>Si la marchandise n'est pas retournée 15 jours après la date de début du dépôt-vente,
         elle sera reputée comme achetée par le client.
        Les frais de retard s'appliqueront jusqu'à cette date.</p>
      <h3 class="cgSubSubTitle">7.7 Exemples</h3>
        <h4 class="cgSubSubTitle">A</h4>
        <p>Alice effectue un dépôt-vente pour une valeur de 500.00 CHF (HT).
         L'évenement a bien marché et Alice retourne la marchandise dans les temps
          pour une valeur de 100.00 CHF (HT). <br/>
        Le calcul est le suivant:<br/>
        <table>
          <tr>
            <td class="tdLineTitle">
              Marchandise vendue:
            </td>
            <td class="tdLineCalculation">
              500.00 - 100.00 =
            </td>
            <td class="tdLineTotal">
              400.00 CHF
            </td>
          </tr>
          <tr>
            <td  class="tdLineTitle">
              Pourcentage de retour:
            </td>
            <td class="tdLineCalculation">
              100.00 / 500.00 =
            </td>
            <td class="tdLineTotal">
              20% => 5% de frais de traitement
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Frais de traitement de retour:
            </td>
            <td class="tdLineCalculation">
              5% * 100.00 =
            </td>
            <td class="tdLineTotal">
               5.00 CHF
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Frais de retard:
            </td>
            <td class="tdLineCalculation">
              0 =
            </td>
            <td class="tdLineTotal">
               0.00 CHF
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Total:
            </td>
            <td class="tdLineCalculation">
              400.00 + 5.00 + 0.00 =
            </td>
            <td class="tdLineTotal">
               405.00 CHF (Hors TVA)
            </td>
          </tr>
        </table>
        </p>
        <h4 class="cgSubSubTitle">B</h4>
        <p>Bob effectue un dépôt-vente pour une valeur de 500.00 CHF (HT).
         L'évenement a moyennement marché et Bob retourne la marchandise dans les temps
          pour une valeur de 400.00 CHF (HT). <br/>
        Le calcul est le suivant:<br/>
        <table>
          <tr>
            <td class="tdLineTitle">
              Marchandise vendue:
            </td>
            <td class="tdLineCalculation">
              500.00 - 400.00 =
            </td>
            <td class="tdLineTotal">
              100.00 CHF
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Pourcentage de retour:
            </td>
            <td class="tdLineCalculation">
              400.00 / 500.00 =
            </td>
            <td  class="tdLineTotal">
              80% => 20% de frais de traitement
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Frais de traitement de retour:
            </td>
            <td class="tdLineCalculation">
              20% * 400.00 =
            </td>
            <td class="tdLineTotal">
               80.00 CHF
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Frais de retard:
            </td>
            <td class="tdLineCalculation">
              0 =
            </td>
            <td class="tdLineTotal">
               0.00 CHF
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Total:
            </td>
            <td class="tdLineCalculation">
              100.00 + 80.00 + 0.00 =
            </td>
            <td class="tdLineTotal">
               180.00 CHF (Hors TVA)
            </td>
          </tr>
        </table>
        </p>
        <h4 class="cgSubSubTitle">C</h4>
        <p>Charlie effectue un dépôt-vente pour une valeur de 500.00 CHF (HT).
         L'évenement a bien marché mais malheureusement Charlie retourne
         la marchandise avec 3 jours de retard.
          pour une valeur de 100.00 CHF (HT). <br/>
        Le calcul est le suivant:<br/>
        <table>
          <tr>
            <td class="tdLineTitle">
              Marchandise vendue:
            </td>
            <td class="tdLineCalculation">
              500.00 - 100.00 =
            </td>
            <td class="tdLineTotal">
              400.00 CHF
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Pourcentage de retour:
            </td>
            <td class="tdLineCalculation">
              100.00 / 500.00 =
            </td>
            <td class="tdLineTotal">
              20% => 5% de frais de traitement
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Frais de traitement de retour:
            </td>
            <td class="tdLineCalculation">
              5% * 100.00 =
            </td>
            <td class="tdLineTotal">
               5.00 CHF
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Frais de retard:
            </td>
            <td class="tdLineCalculation">
              3 * 1% * 500.00 =
            </td>
            <td class="tdLineTotal">
               15.00 CHF
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Total:
            </td>
            <td class="tdLineCalculation">
              400.00 + 5.00 + 15.00 =
            </td>
            <td class="tdLineTotal">
               420.00 CHF (Hors TVA)
            </td>
          </tr>
        </table>
        </p>
  </div>
  <va-divider />
  <div class="CG CGEn">
    <h1 class="cgTitle">Terms and Conditions Japort SA</h1>
    <p class="cgTitle">
      The present terms and conditions are effective as of the 1st December 2021
    </p>
    <h2 class="cgSubTitle">1. Clients</h2>
    <p>
      To be eligible as a client, you must be registered as a Swiss company
      with a purpose corresponding to reselling our merchandise.
    </p>
    <h2 class="cgSubTitle">2. Orders</h2>
    <p>
      Any order is deemed accepted by both parties,
      after a period of 7 days, upon receipt of the order confirmation.
      Payment for goods in stock is due after this period.
      All goods remain the property of Japort SA
      until it is paid in full.
    </p>
    <h2 class="cgSubTitle">3. Preorders</h2>
    <p>
      Any pre-order must be paid at 50% of the value of the pre-ordered goods,
      at the time of the order, to be validated.
      The amount acts as a guarantee against pre-orders and abusive cancellations.
      Thus, this amount cannot be refunded, in any way.
      The balance of payment is due once the merchandise is in stock
    </p>
    <h2 class="cgSubTitle">4. Delivery</h2>
    <p>
      All orders are delivered free of charge from CHF 400.00, excluding VAT.
      In case your order does not reach 400.00 CHF,
      excluding VAT, and without notice to the contrary from you,
      we keep the goods for a maximum of two weeks,
      to allow you to reach CHF 400.00, excluding VAT.
      After this period, or if you ask us,
      we send your goods against payment of the shipping and packing costs,
      upon receipt of the package, for an amount of CHF 30.00, excluding VAT, per package.
      Once the goods leave our company,
      the responsibility of them is passed on to you.
    </p>
    <h2 class="cgSubTitle">5. Cancellations</h2>
    <p>
      Any cancellation due to the Manufacturers will be sent to you as soon as we are informed.
      In the event that you have already paid for all or part of the canceled goods,
      a credit note will be deducted from your next invoice.
      Any damage cannot be claimed from us.
    </p>
    <h2 class="cgSubTitle">6. Returns</h2>
    <p>
      Any return is strictly exceptional and at your expense.
      A request by email must be made before any return of goods,
      telling us why you want to return.
    </p>
    <h2 class="cgSubTitle">7. Sale on consignment</h2>
    <p>
      For punctual events, we offer the possibility to our client to make a sale on consignment.
       The latest is subject to the following terms:
    </p>
      <h3 class="cgSubSubTitle">7.1 Definition</h3>
      A sale on consignment is when a seller sends goods to a reseller who pays the seller
       only as and when the goods are sold during a punctual event.
      The client may return the merchandise quantities
      that He wishes at the end of the sale on consignment.
      The client will be invoiced at the end of the sale on consignment.
      The invoice will comprise the merchandise sold, eventual return treatment and delay fees.
      <h3 class="cgSubSubTitle">7.2 Minimum amount</h3>
      The minimal amount of a sale on consignment is <strong>400.00 CHF</strong>, without VAT.
      No sale on consignment will be done under this amount.
      <h3 class="cgSubSubTitle">7.3 Delivery</h3>
      Delivery from Japort SA to the client is taken into charge by Japort SA.
      It is possible, on the client's demand, to deliver the goods directly to the event location.
      <h3 class="cgSubSubTitle">7.4 Return conditions</h3>
      <p> Goods must be returned intact, without any label
        or any other items non-present when the goods were delivered.</p>
      <p> Return's responsibilities and eventual fees are
      to be taken into charge by the client.</p>
      <p> Any defect found will result in the refusal of the return of the goods.
        Return processing fees will apply to refusals.</p>
      <h3 class="cgSubSubTitle">7.5 Return processing fees</h3>
        <p>Depending on the amount of the goods returned,
          a percentage may be applied as return processing fees.</p>
        <ul>
          <li>00% to 25% of total sale on consignment returned:
            <strong>5%</strong> return processing fees (Without VAT)</li>
          <li>26% to 50% of total sale on consignment returned:
            <strong>10%</strong> return processing fees (Without VAT)</li>
          <li>51% to 75% of total sale on consignment returned:
            <strong>15%</strong> return processing fees (Without VAT)</li>
          <li>76% and more of total sale on consignment returned:
            <strong>20%</strong> return processing fees (Without VAT)</li>
        </ul>
      <h3 class="cgSubSubTitle">7.6 Sale on consignment duration</h3>
        <p>The duration of the sale on consignment is <strong>5 days</strong> maximum.</p>
        <p>Any exceed duration will result in delay fees of 1%
           of the total sale on consignment amount per day delayed.
        </p>
        <p>If the goods are not returned 15 days after the start of the sale on consignment,
          the goods will be deemed as purchased by the customer.
          The delay fees will apply until this date.</p>
      <h3 class="cgSubSubTitle">7.7 Examples</h3>
        <h4 class="cgSubSubTitle">A</h4>
        <p>Alice does a sale on consignment for a total amount of 500.00 (without VAT).
        The event went well and Alice return the goods
         in time for an amount of 100.00 (without VAT). <br/>
         The calculation will be the following:<br/>
        <table>
          <tr>
            <td class="tdLineTitle">
              Sold goods:
            </td>
            <td class="tdLineCalculation">
              500.00 - 100.00 =
            </td>
            <td class="tdLineTotal">
              400.00 CHF
            </td>
          </tr>
          <tr>
            <td  class="tdLineTitle">
              Return percentage:
            </td>
            <td class="tdLineCalculation">
              100.00 / 500.00 =
            </td>
            <td class="tdLineTotal">
              20% => 5% of return processing fees
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Return processing fees:
            </td>
            <td class="tdLineCalculation">
              5% * 100.00 =
            </td>
            <td class="tdLineTotal">
               5.00 CHF
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Delay fees:
            </td>
            <td class="tdLineCalculation">
              0 =
            </td>
            <td class="tdLineTotal">
               0.00 CHF
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Total:
            </td>
            <td class="tdLineCalculation">
              400.00 + 5.00 + 0.00 =
            </td>
            <td class="tdLineTotal">
               405.00 CHF (without TVA)
            </td>
          </tr>
        </table>
        </p>
        <h4 class="cgSubSubTitle">B</h4>
        <p>Bob does a sale on consignment for a total amount of 500.00 (without VAT).
          The event didn't went well and Bob  return the goods
         in time for an amount of 400.00 (without VAT). <br/>
         The calculation will be the following:<br/>
        <table>
          <tr>
            <td class="tdLineTitle">
              Sold goods:
            </td>
            <td class="tdLineCalculation">
              500.00 - 400.00 =
            </td>
            <td class="tdLineTotal">
              100.00 CHF
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Return percentage:
            </td>
            <td class="tdLineCalculation">
              400.00 / 500.00 =
            </td>
            <td  class="tdLineTotal">
              80% => 20% of return processing fees
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Return processing fees:
            </td>
            <td class="tdLineCalculation">
              20% * 400.00 =
            </td>
            <td class="tdLineTotal">
               80.00 CHF
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Delay fees:
            </td>
            <td class="tdLineCalculation">
              0 =
            </td>
            <td class="tdLineTotal">
               0.00 CHF
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Total:
            </td>
            <td class="tdLineCalculation">
              100.00 + 80.00 + 0.00 =
            </td>
            <td class="tdLineTotal">
               180.00 CHF (Without VAT)
            </td>
          </tr>
        </table>
        </p>
        <h4 class="cgSubSubTitle">C</h4>
        <p>Charlie does a sale on consignment for a total amount of 500.00 (without VAT)..
          The event went well but unfortunately Charlie return the goods with 3 days delay,
          for an amount of 100.00 (without VAT).<br/>
          The calculation will be the following:<br/>
        <table>
          <tr>
            <td class="tdLineTitle">
              Sold goods:
            </td>
            <td class="tdLineCalculation">
              500.00 - 100.00 =
            </td>
            <td class="tdLineTotal">
              400.00 CHF
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Return percentage:
            </td>
            <td class="tdLineCalculation">
              100.00 / 500.00 =
            </td>
            <td class="tdLineTotal">
              20% => 5% of return processing fees
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Return processing fees:
            </td>
            <td class="tdLineCalculation">
              5% * 100.00 =
            </td>
            <td class="tdLineTotal">
               5.00 CHF
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Delay fees:
            </td>
            <td class="tdLineCalculation">
              3 * 1% * 500.00 =
            </td>
            <td class="tdLineTotal">
               15.00 CHF
            </td>
          </tr>
          <tr>
            <td class="tdLineTitle">
              Total:
            </td>
            <td class="tdLineCalculation">
              400.00 + 5.00 + 15.00 =
            </td>
            <td class="tdLineTotal">
               420.00 CHF (Without VAT)
            </td>
          </tr>
        </table>
        </p>
    <h2 class="cgSubTitle">8. Rights</h2>
    <p>
      In case of doubts, the french version of this present terms and conditions is righteous.
    </p>
  </div>
</template>

<style>
.CG {
  text-align: justify;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.cgTitle{
  text-align: center;
}

.cgSubTitle {
  margin-top: 5px;
  margin-bottom: 10px;
}

.cgSubSubTitle {
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 300;
  font-style: italic;
}

.tdLineTitle {
  font-weight: bold;
  text-align: right;
}

.tdLineCalculation {
  padding-left: 10px;
  text-align: right;
  font-style: italic;
}

.tdLineTotal {
  padding-left: 10px;
  font-weight: bold;
}
</style>
