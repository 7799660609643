
import { defineComponent } from 'vue';
import ClientSidebar from '@/components/ClientSidebar.vue';

const data = {
};

export default defineComponent({
  name: 'ClientsOrders',
  data() {
    return data;
  },
  components: {
    ClientSidebar,
  },
  methods: {
  },
});
