import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_sidebar_item_title = _resolveComponent("va-sidebar-item-title")!
  const _component_va_sidebar_item_content = _resolveComponent("va-sidebar-item-content")!
  const _component_va_sidebar_item = _resolveComponent("va-sidebar-item")!
  const _component_va_sidebar = _resolveComponent("va-sidebar")!

  return (_openBlock(), _createBlock(_component_va_sidebar, {
    position: "left",
    class: "sidebar"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_va_sidebar_item, {
        active: _ctx.route.name === 'Espace Pro - Mon compte',
        to: "/account"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_va_sidebar_item_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_va_icon, { name: "person" }),
              _createVNode(_component_va_sidebar_item_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Mon compte")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["active"]),
      _createVNode(_component_va_sidebar_item, {
        active: _ctx.route.name === 'Espace Pro - Mes commandes',
        to: "/account/order"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_va_sidebar_item_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_va_icon, { name: "shopping_bag" }),
              _createVNode(_component_va_sidebar_item_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Mes commandes")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["active"]),
      _createVNode(_component_va_sidebar_item, {
        active: _ctx.route.name === 'Espace Pro - Mes livraisons',
        to: "/account/delivery"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_va_sidebar_item_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_va_icon, { name: "local_shipping" }),
              _createVNode(_component_va_sidebar_item_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Mes livraisons")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["active"]),
      _createVNode(_component_va_sidebar_item, {
        active: _ctx.route.name === 'Espace Pro - Mes factures',
        to: "/account/invoices"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_va_sidebar_item_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_va_icon, { name: "payments" }),
              _createVNode(_component_va_sidebar_item_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Mes factures")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["active"]),
      (_ctx.isAdmin)
        ? (_openBlock(), _createBlock(_component_va_sidebar_item, {
            key: 0,
            active: _ctx.route.name === 'Espace Pro - Administration',
            to: "/admin"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_va_sidebar_item_content, null, {
                default: _withCtx(() => [
                  _createVNode(_component_va_icon, { name: "admin_panel_settings" }),
                  _createVNode(_component_va_sidebar_item_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Administration")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["active"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}