import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import Brands from '../views/Brands.vue';
import GeneralCondition from '../views/GeneralCondition.vue';
import Contact from '../views/Contact.vue';
import Login from '../views/Login.vue';
import Products from '../views/Products.vue';
import Cart from '../views/Cart.vue';
import VenteDirect from '../views/VenteDirect.vue';
import ProductsDashboard from '../views/ProductsDashboard.vue';
import ProductsDashboardProduct from '../views/ProductsDashboardProduct.vue';
import Account from '../views/Account.vue';
import AccountDeliveries from '../views/AccountDeliveries.vue';
import AccountInvoices from '../views/AccountInvoices.vue';
import AccountOrders from '../views/AccountOrders.vue';
import ProductDetail from '../components/ProductDetail.vue';
import SupplierDashboard from '../views/SupplierDashboard.vue';
import SupplierIncomming from '../views/SupplierIncomming.vue';
import SupplierOrders from '../views/SupplierOrders.vue';
import SupplierOrdersWaiting from '../views/SupplierOrdersWaiting.vue';
import ClientDashboard from '../views/ClientDashboard.vue';
import ClientDeliveries from '../views/ClientDeliveries.vue';
import ClientOrders from '../views/ClientOrders.vue';
import ClientGalaxus from '../views/ClientGalaxus.vue';
import AdminDashboard from '../views/AdminDashboard.vue';

import store from '../store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/brands',
    name: 'Brands',
    component: Brands,
  },
  {
    path: '/conditionGeneral',
    name: 'Condition Générale',
    component: GeneralCondition,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/login/reset',
    name: 'Reset Login',
    component: Login,
  },
  {
    path: '/pro/products',
    name: 'Espace Pro - Produits',
    component: Products,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pro/products/:id',
    name: 'Espace Pro - Detail Produits',
    component: ProductDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pro/cart',
    name: 'Espace Pro - Panier',
    component: Cart,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account',
    name: 'Espace Pro - Mon compte',
    component: Account,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account/delivery',
    name: 'Espace Pro - Mes livraisons',
    component: AccountDeliveries,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account/invoices',
    name: 'Espace Pro - Mes factures',
    component: AccountInvoices,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account/order',
    name: 'Espace Pro - Mes commandes',
    component: AccountOrders,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pro/sell',
    name: 'Espace Pro - Vente direct',
    component: VenteDirect,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/pro/managment',
    name: 'Espace Pro - Managment',
    component: ProductsDashboard,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/pro/managment/product',
    name: 'Espace Pro - Managment Product',
    component: ProductsDashboardProduct,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/pro/managment/catalog',
    name: 'Espace Pro - Managment Catalog',
    component: ProductsDashboard,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/supplier',
    name: 'Espace Pro - Fournisseurs',
    component: SupplierDashboard,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/supplier/incomming',
    name: 'Espace Pro - Arrivages Fournisseur',
    component: SupplierIncomming,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/supplier/incomming/:id(\\d+)',
    name: 'Espace Pro - Arrivage Fournisseur',
    component: SupplierIncomming,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/supplier/orders',
    name: 'Espace Pro - Commandes Fournisseurs',
    component: SupplierOrders,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/supplier/waiting',
    name: 'Espace Pro - Commandes Fournisseurs en Attente',
    component: SupplierOrdersWaiting,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/client',
    name: 'Espace Pro - Clients',
    component: ClientDashboard,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/client/deliveries',
    name: 'Espace Pro - Livraisons Clients',
    component: ClientDeliveries,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/client/deliveries/:id(\\d+)',
    name: 'Espace Pro - Livraison Clients',
    component: ClientDeliveries,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/client/orders',
    name: 'Espace Pro - Commandes Clients',
    component: ClientOrders,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/client/orders/galaxus',
    name: 'Espace Pro - Commande Galaxus',
    component: ClientGalaxus,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin',
    name: 'Espace Pro - Administration',
    component: AdminDashboard,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  strict: true, // applies to all routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    // this route requires to be an admin, check if logged in and if admin
    // if not, redirect to login page.
    if (!store.getters.isLoggedIn || !store.getters.isAdmin) {
      next({
        name: 'Login',
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next(); // go to wherever I'm going
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLoggedIn) {
      next({
        name: 'Login',
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next(); // go to wherever I'm going
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

export default router;
