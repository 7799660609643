import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-001210ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "proSupplierIncomming" }
const _hoisted_2 = {
  key: 0,
  class: "contentDashboard"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { style: {"border-top":"1px solid black"} }
const _hoisted_5 = { colspan: "7" }
const _hoisted_6 = { colspan: "1" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = {
  key: 1,
  class: "contentDashboard"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SupplierSidebar = _resolveComponent("SupplierSidebar")!
  const _component_va_progress_bar = _resolveComponent("va-progress-bar")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_image = _resolveComponent("va-image")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_counter = _resolveComponent("va-counter")!
  const _component_va_data_table = _resolveComponent("va-data-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SupplierSidebar),
    (_ctx.routeId !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.incomming === undefined || _ctx.loading)
            ? (_openBlock(), _createBlock(_component_va_progress_bar, {
                key: 0,
                indeterminate: "",
                class: "progressCircle"
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createTextVNode(" Arrivage du " + _toDisplayString(_ctx.incomming.date) + " de " + _toDisplayString(_ctx.incomming.origin.name) + " ", 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.incomming.parcels, (parcel, index) => {
                  return (_openBlock(), _createBlock(_component_va_data_table, {
                    key: parcel.ID,
                    items: parcel.products,
                    columns: _ctx.columns,
                    hoverable: true,
                    clickable: true,
                    loading: _ctx.loading,
                    striped: ""
                  }, {
                    headerPrepend: _withCtx(() => [
                      _createElementVNode("tr", _hoisted_4, [
                        _createElementVNode("th", _hoisted_5, _toDisplayString(parcel.trackingNumber), 1),
                        _createElementVNode("th", _hoisted_6, [
                          _createTextVNode(" Tout Reçu "),
                          _createVNode(_component_va_button, {
                            icon: "done_all",
                            size: "small",
                            onClick: ($event: any) => (_ctx.parcelDone(parcel))
                          }, null, 8, ["onClick"])
                        ])
                      ])
                    ]),
                    "cell(pictures)": _withCtx(({ value }) => [
                      _createVNode(_component_va_image, {
                        contain: "",
                        class: "cartProductImage",
                        src: value.split(',')[0]
                      }, null, 8, ["src"])
                    ]),
                    "cell(ean)": _withCtx(({ value }) => [
                      _createTextVNode(_toDisplayString(value) + " ", 1),
                      _createVNode(_component_va_button, {
                        icon: "content_copy",
                        size: "small",
                        onClick: ($event: any) => (_ctx.copyToClipboard(String(value).slice(0,-1)))
                      }, null, 8, ["onClick"])
                    ]),
                    "cell(weight)": _withCtx(({ rowIndex }) => [
                      (parcel.products !== undefined)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                            (_ctx.weightRowIsEditable(index,rowIndex))
                              ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                                  _createVNode(_component_va_input, {
                                    modelValue: parcel.products[rowIndex].product.weight,
                                    "onUpdate:modelValue": ($event: any) => ((parcel.products[rowIndex].product.weight) = $event),
                                    onChange: ($event: any) => (_ctx.updateWeight(parcel.products[rowIndex].product.variantID,
                        parcel.products[rowIndex].product.weight,
                        index,
                        rowIndex))
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]),
                                  _createVNode(_component_va_button, {
                                    icon: "done",
                                    size: "small",
                                    onClick: ($event: any) => (_ctx.doneWeightRow(index, rowIndex))
                                  }, null, 8, ["onClick"])
                                ]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_9, [
                                  _createTextVNode(_toDisplayString(parcel.products[rowIndex].product.weight) + " ", 1),
                                  _createVNode(_component_va_button, {
                                    icon: "edit",
                                    size: "small",
                                    onClick: ($event: any) => (_ctx.editWeightRow(index,rowIndex))
                                  }, null, 8, ["onClick"])
                                ]))
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    "cell(price)": _withCtx(({ value }) => [
                      _createTextVNode(_toDisplayString(_ctx.monetaryFormat.format(value)), 1)
                    ]),
                    "cell(expectedQuantity)": _withCtx(({ rowIndex }) => [
                      (parcel.products !== undefined)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                            (_ctx.expectedRowIsEditable(index,rowIndex))
                              ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                                  _createVNode(_component_va_counter, {
                                    modelValue: parcel.products[rowIndex].expectedQuantity,
                                    "onUpdate:modelValue": [($event: any) => ((parcel.products[rowIndex].expectedQuantity) = $event), ($event: any) => (_ctx.updateExpected(parcel.products[rowIndex].ID,
                        parcel.products[rowIndex].expectedQuantity))],
                                    min: 0,
                                    "manual-input": ""
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                  _createVNode(_component_va_button, {
                                    icon: "done",
                                    size: "small",
                                    onClick: ($event: any) => (_ctx.doneExpectedRow(index, rowIndex))
                                  }, null, 8, ["onClick"])
                                ]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_12, [
                                  _createTextVNode(_toDisplayString(parcel.products[rowIndex].expectedQuantity) + " ", 1),
                                  _createVNode(_component_va_button, {
                                    icon: "edit",
                                    size: "small",
                                    onClick: ($event: any) => (_ctx.editExpectedRow(index,rowIndex))
                                  }, null, 8, ["onClick"])
                                ]))
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    "cell(receivedQuantity)": _withCtx(({ rowIndex }) => [
                      (parcel.products !== undefined)
                        ? (_openBlock(), _createBlock(_component_va_counter, {
                            key: 0,
                            modelValue: parcel.products[rowIndex].receivedQuantity,
                            "onUpdate:modelValue": [($event: any) => ((parcel.products[rowIndex].receivedQuantity) = $event), ($event: any) => (_ctx.updateReceived(parcel.products[rowIndex].ID,
                parcel.products[rowIndex].receivedQuantity))],
                            min: 0,
                            max: Number(parcel.products[rowIndex].expectedQuantity),
                            "manual-input": ""
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "max"]))
                        : _createCommentVNode("", true)
                    ]),
                    "cell(actions)": _withCtx(({ rowIndex }) => [
                      _createVNode(_component_va_button, {
                        icon: "search",
                        size: "small",
                        onClick: ($event: any) => (_ctx.rowClick(parcel.products[rowIndex].product.variantID))
                      }, null, 8, ["onClick"])
                    ]),
                    _: 2
                  }, 1032, ["items", "columns", "loading"]))
                }), 128))
              ]))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createTextVNode(" All Incommings "),
          _createVNode(_component_va_data_table, {
            items: _ctx.incommingList,
            columns: _ctx.columnsIncommings,
            hoverable: true,
            clickable: true,
            loading: _ctx.loading,
            "onRow:click": _ctx.rowClickIncommings,
            striped: ""
          }, {
            "cell(parcels)": _withCtx(({ rowIndex }) => [
              _createTextVNode(_toDisplayString(_ctx.displayParcels(rowIndex)), 1)
            ]),
            _: 1
          }, 8, ["items", "columns", "loading", "onRow:click"])
        ]))
  ]))
}