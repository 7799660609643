
import { defineComponent } from 'vue';
import ProductPreview from '@/components/ProductPreview.vue'; // @ is an alias to /src
import axios from 'axios';
import Catalog from './types/Catalog';
import Product from './types/Product';
import License from './types/License';
import MasterType from './types/MasterType';
import Manufacturer from './types/Manufacturer';
import Type from './types/Type';

export default defineComponent({
  name: 'ProductShop',
  components: {
    ProductPreview,
  },
  data: () => ({
    sidebar: {
      type: false,
      license: false,
      manufacturer: false,
      stock: false,
    },
    products: new Array<Product>(),
    filteredProducts: new Array<Product>(),
    optionsLicenses: new Array<License>(),
    selectionsLicenses: new Array<License>(),
    optionsTypes: new Array<MasterType>(),
    selectionsTypes: new Array<MasterType>(),
    optionsManufacturers: new Array<Manufacturer>(),
    selectionsManufacturers: new Array<Manufacturer>(),
    selectionsStock: new Array<{id:number, name:string}>(),
    optionsStock: [{ id: 0, name: 'En stock' },
      { id: 1, name: 'En précommande' },
      { id: 2, name: 'Sur commande' }],
    orderOptions: [{ id: 0, name: 'Stock Ascendant' },
      { id: 1, name: 'Stock Descendant' },
      { id: 2, name: 'Date de sortie Ascendant' },
      { id: 3, name: 'Date de sortie Descendant' },
      { id: 4, name: 'Prix Ascendant' },
      { id: 5, name: 'Prix Descendant' },
      { id: 6, name: 'Nouveautés' },
      { id: 7, name: 'État de stock' },
      { id: 8, name: 'Date de précommande, plus proche d\'abord' },
      { id: 9, name: 'Date de précommande, plus loin d\'abord' }],
    appliedFilters: '',
    orderBy: 6,
    loading: false,
    page: 1,
    numberPerPage: 25,
    searchInput: '',
    timeout: 0,
  }),
  computed: {
    productsSize() {
      return this.filteredProducts.length;
    },
    productPaginate() {
      return this.filteredProducts.slice(
        (this.page - 1),
        (this.page - 1) + this.numberPerPage,
      );
    },
    filter() {
      return this.searchInput.length !== 0
      || this.selectionsLicenses.length > 0
      || this.selectionsTypes.length > 0
      || this.selectionsManufacturers.length > 0
      || this.selectionsStock.length > 0;
    },
    searchString() {
      const searchArgs = {
        search: this.searchInput.length !== 0 ? this.searchInput : undefined,
        licenses: this.selectionsLicenses.length > 0
          ? this.selectionsLicenses.map((lic:License) => lic.ID) : undefined,
        types: this.selectionsTypes.length > 0
          ? this.selectionsTypes.map((typ:Type) => typ.ID) : undefined,
        manufacturer: this.selectionsManufacturers.length > 0
          ? this.selectionsManufacturers.map((man:Manufacturer) => man.ID) : undefined,
        stock: this.selectionsStock.length > 0
          ? this.selectionsStock.map((sto:{id: number, name: string}) => sto.id) : undefined,
      };

      return JSON.stringify(searchArgs);
    },
    selectedAddressID() {
      return this.$store.getters.getSelectedAddress.ID;
    },
  },
  created() {
    this.loading = true;
    const fetchFunction = (url:string) => axios.get(url, {
      headers: {
        Authorization: `Bearer ${this.$store.getters.token}`,
      },
    });

    const URLStock = `/etatstock/${this.selectedAddressID}`;
    const URLLicenses = '/licence';
    const URLTypes = '/maitretype';
    const URLManufacturers = '/fabricant';

    const promiseArray = [URLStock, URLLicenses, URLTypes, URLManufacturers]
      .map(fetchFunction);

    Promise.all(promiseArray)
      .then((data) => {
        this.products = (data[0] as any).data.map((d: any) => {
          const catalogs = new Array<Catalog>();

          if (d.CatalogueIDS !== null) {
            const catalogIDs = d.CatalogueIDS.split(';');
            const catalogNames = d.CatalogueLibelles.split(';');
            let catalogEndDates = new Array<Date>();
            if (d.CatalogeDatesFins !== null) {
              catalogEndDates = d.CatalogeDatesFins.split(';');
            }
            for (let index = 0; index < catalogIDs.length; index += 1) {
              const catalog: Catalog = {
                ID: catalogIDs[index],
                name: catalogNames[index],
                endDate: catalogEndDates[index] !== undefined
                  ? new Date(catalogEndDates[index]) : undefined,
              };
              catalogs.push(catalog);
            }
          }

          const prod: Product = {
            productID: d.pid,
            variantID: d.vid,
            releaseDate: new Date(d.dateSortie),
            incommingDate: new Date(d.dateArrivage),
            license: {
              ID: d.licence,
              FR: d.LicencesLibelleFR,
              JP: d.LicencesLibelleJP,
              EN: d.LicencesLibelleEN,
              DE: d.LicencesLibelleDE,
              IT: d.LicencesLibelleIT,
              master: d.LicencesMaitre,
            },
            manufacturer: {
              ID: d.fabricant,
              FR: d.FabricantsLibelleFR,
              JP: d.FabricantsLibelleJP,
            },
            price: d.pv,
            msrp: d.pvc,
            type: {
              ID: d.type,
              FR: d.TypesLibelleFR,
              JP: d.TypesLibelleJP,
              EN: d.TypesLibelleEN,
              DE: d.TypesLibelleIT,
              IT: d.TypesLibelleIT,
              master: d.TypesMaitre,
            },
            boxOf: d.boiteDe,
            packingSize: d.colisage,
            ean: d.ean,
            weight: d.poids,
            characters: d.Personnages !== null ? d.Personnages.split(',') : undefined,
            productPictures: d.PhotosProduit !== null ? d.PhotosProduit.split(',') : undefined,
            variantPictures: d.PhotosVariante !== null ? d.PhotosVariante.split(',') : undefined,
            nameFR: d.NomFR,
            nameEN: d.NomEN,
            nameDE: d.NomDE,
            nameIT: d.NomIT,
            catalog: catalogs,
            available: d.disponiblePositif,
            createdAt: new Date(d.VarianteCreatedAt),
            updatedAt: new Date(d.VarianteUpdatedAt),
          };

          return prod;
        });
        this.optionsLicenses = (data[1] as any).data.map((d:any) => ({
          ID: d.id,
          FR: d.libelleFR,
          JP: d.libelleJP,
          EN: d.libelleEN,
          DE: d.libelleDE,
          IT: d.libelleIT,
          master: d.maitreLicence,
        })).sort((a: License, b: License) => a.FR.localeCompare(b.FR));
        this.optionsTypes = (data[2] as any).data.map((d:any) => ({
          ID: d.id,
          FR: d.libelleFR,
          JP: d.libelleJP,
          EN: d.libelleEN,
          DE: d.libelleDE,
          IT: d.libelleIT,
        })).sort((a: Type, b: Type) => a.FR.localeCompare(b.FR));
        this.optionsManufacturers = (data[3] as any).data.map((d:any) => ({
          ID: d.id,
          FR: d.libelleFR,
          JP: d.libelleJP,
        })).sort((a: Manufacturer, b: Manufacturer) => a.FR.localeCompare(b.FR));

        // get url filter and apply filter
        const searchString = JSON.stringify(this.$route.query);
        const searchArgs = JSON.parse(searchString);

        // change selector and input
        if (searchArgs.search !== undefined) {
          this.searchInput = searchArgs.search;
        }
        if (searchArgs.licenses !== undefined) {
          this.sidebar.license = true;
          this.selectionsLicenses = this.optionsLicenses
            .filter((lic:License) => searchArgs.licenses.includes(lic.ID));
        }
        if (searchArgs.types !== undefined) {
          this.sidebar.type = true;
          this.selectionsTypes = this.optionsTypes
            .filter((typ:Type) => searchArgs.types.includes(typ.ID));
        }
        if (searchArgs.manufacturer !== undefined) {
          this.sidebar.manufacturer = true;
          this.selectionsManufacturers = this.optionsManufacturers
            .filter((man:Manufacturer) => searchArgs.manufacturer.includes(man.ID));
        }
        if (searchArgs.stock !== undefined) {
          this.sidebar.stock = true;
          this.selectionsStock = this.optionsStock
            .filter((sto: {id:number, name: string}) => searchArgs.stock.includes(sto.id));
        }

        if (this.searchString === '{}' || this.searchString === undefined) {
          this.filterProduct(this.searchString);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => { this.loading = false; });
  },
  watch: {
    orderBy: {
      handler(newOrderBy) {
        // this will be run immediately on component creation.
        const compareFunction = this.getSortByNumber(newOrderBy);
        this.filteredProducts.sort((a, b) => compareFunction(a, b));
      },
    },
    searchString: {
      // This will let Vue know to look inside the array
      deep: true,
      handler(newSearchString) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.searchString, 300, newSearchString);
        this.$router.replace({ path: '/pro/products', query: JSON.parse(newSearchString) });
        this.filterProduct(newSearchString);
      },
    },
  },
  methods: {
    filterProduct(searchString: string) {
      console.log('calling filter');
      // this.appliedFilters = `s=${this.searchInput.toUpperCase()}&`
      const compareFunction = this.getSortByNumber(this.orderBy);

      // extract search args from string
      const searchArgs = JSON.parse(searchString);

      // then filter products
      this.filteredProducts = this.products.filter(
        (product:Product) => ((searchArgs.search === undefined
        || product.nameFR.toUpperCase().includes(searchArgs.search.toUpperCase())
        || product.ean.toString() === searchArgs.search
        || `V${product.variantID}` === `V${searchArgs.search}`
        || `P${product.productID}` === `P${searchArgs.search}`)
        && (searchArgs.licenses === undefined
        || searchArgs.licenses.includes(product.license.ID))
        && (searchArgs.types === undefined
        || searchArgs.types.includes(product.type.master))
        && (searchArgs.manufacturer === undefined
        || searchArgs.manufacturer.includes(product.manufacturer.ID))
        && (searchArgs.stock === undefined
        || this.stockSelection(searchArgs.stock, product))
        ),
      ).sort((a, b) => compareFunction(a, b));

      // TODO add additionnal close product based on leven
    },
    clearFilter() {
      this.appliedFilters = '';
      this.searchInput = '';
      this.selectionsLicenses = new Array<License>();
      this.selectionsTypes = new Array<MasterType>();
      this.selectionsManufacturers = new Array<Manufacturer>();
      this.selectionsStock = [];
      this.filterProduct('');
    },
    stockSelection(stockSelected: Array<number>, pro:Product): boolean {
      // eslint-disable-next-line consistent-return
      for (let index = 0; index < stockSelected.length; index += 1) {
        const sel = stockSelected[index];
        switch (sel) {
          case 0: if (pro.available > 0) {
            console.log(pro.available);
            return true;
          }
            break;
          case 1: if (pro.catalog.filter((c) => c.endDate !== undefined).length > 0) {
            return true;
          }
            break;
          case 2: if (pro.catalog.filter((c) => c.endDate === undefined).length > 0
                    && pro.available <= 0) {
            return true;
          }
            break;
          default: break;
        }
      }
      return false;
    },
    removeSelectedLicence(selected: License) {
      const index = this.selectionsLicenses.indexOf(selected);
      if (index > -1) { // only splice array when item is found
        this.selectionsLicenses.splice(index, 1);
      }
    },
    removeSelectedType(selected: MasterType) {
      const index = this.selectionsTypes.indexOf(selected);
      if (index > -1) { // only splice array when item is found
        this.selectionsTypes.splice(index, 1);
      }
    },
    removeSelectedManufacturer(selected: Manufacturer) {
      const index = this.selectionsManufacturers.indexOf(selected);
      if (index > -1) { // only splice array when item is found
        this.selectionsManufacturers.splice(index, 1);
      }
    },
    removeSelectedStock(selected: {id: number, name: string}) {
      const index = this.selectionsStock.indexOf(selected);
      if (index > -1) { // only splice array when item is found
        this.selectionsStock.splice(index, 1);
      }
    },
    getEndTimeOfCatalogNear(catalog: any):number {
      if (catalog === undefined) {
        return 0;
      }
      if (catalog.endDate === undefined) {
        return 0;
      }
      return catalog.endDate.getTime();
    },
    getEndTimeOfCatalogFar(catalog: any):number {
      if (catalog === undefined) {
        return Number.MAX_VALUE;
      }
      if (catalog.endDate === undefined) {
        return Number.MAX_VALUE;
      }
      return catalog.endDate.getTime();
    },
    /*
    return 0 if product is preorder
    1 if in stock
    2 if on order
    */
    getProductStockValue(pro: Product): number {
      if (pro.available > 0) {
        return 1;
      }
      if (pro.catalog.filter((c) => c.endDate !== undefined).length > 0) {
        return 0;
      }
      return 2;
    },
    getSortByNumber(input:number) {
      const sortStockASC = function stockA(a: any, b: any) { return a.available - b.available; };
      const sortStockDesc = function StockD(a: any, b: any) { return b.available - a.available; };
      const sortDateAsc = function DateA(a: any, b: any) {
        return a.releaseDate.getTime() - b.releaseDate.getTime();
      };
      const sortDateDesc = function DateD(a: any, b: any) {
        return b.releaseDate.getTime() - a.releaseDate.getTime();
      };
      const sortPriceAsc = function PriceA(a: any, b: any) { return a.price - b.price; };
      const sortPriceDesc = function PriceD(a: any, b: any) { return b.price - a.price; };

      const sortNews = function News(a: any, b: any) {
        return b.createdAt.getTime() - a.createdAt.getTime();
      };
      // Prorder -> Stock -> Precommande
      const sortStockStatus = (a: Product, b: Product) => this.getProductStockValue(a)
       - this.getProductStockValue(b);

      const sortPreorderDateNear = (a: any, b: any) => this.getEndTimeOfCatalogNear(
        b.catalog[0],
      ) - this.getEndTimeOfCatalogNear(a.catalog[0]);
      const sortPreorderDateFar = (a: any, b: any) => this.getEndTimeOfCatalogFar(
        a.catalog[0],
      ) - this.getEndTimeOfCatalogFar(b.catalog[0]);

      const sortDefault = function D(a: any, b: any) { return a.variantID - b.variantID; };

      switch (input) {
        case 0:
          return sortStockASC;
        case 1:
          return sortStockDesc;
        case 2:
          return sortDateAsc;
        case 3:
          return sortDateDesc;
        case 4:
          return sortPriceAsc;
        case 5:
          return sortPriceDesc;
        case 6:
          return sortNews;
        case 7:
          return sortStockStatus;
        case 8:
          return sortPreorderDateNear;
        case 9:
          return sortPreorderDateFar;
        default:
          return sortDefault;
      }
    },
  },
});
