
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'SupplierSidebar',
  data() {
    return {
      accordionValue: [false],
    };
  },
  computed: { route: () => useRoute() },
});
