
import { defineComponent } from 'vue';
import ClientSidebar from '@/components/ClientSidebar.vue';
import { VaFile, VaFileUpload, VaProgressBar } from 'vuestic-ui';
import { XMLParser } from 'fast-xml-parser';
import Product from '@/components/types/Product';
import axios from 'axios';
import Catalog from '@/components/types/Catalog';
import GalaxusProduct from '@/components/types/GalaxusProduct';

const monetaryFormat = new Intl.NumberFormat('fr-CH', {
  style: 'currency',
  currency: 'CHF',
});

export default defineComponent({
  name: 'ClientsGalaxus',
  data: () => ({
    fileLink: {} as VaFile,
    fileLoaded: false,
    galaxusOrder: new Array<GalaxusProduct>(),
    loading: false,
    monetaryFormat,
    stock: new Array<Product>(),
    galaxusOrderNumber: 0,
  }),
  components: {
    ClientSidebar,
  },
  computed: {
    orderTotalAmount(): number {
      return this.galaxusOrder.reduce(
        (previousValue, currentValue) => previousValue
                      + Number((currentValue.quantity
                      * currentValue.product.price).toFixed(2)),
        0,
      );
    },
    galaxusTotalAmount(): number {
      return this.galaxusOrder.reduce(
        (previousValue, currentValue) => previousValue
                      + currentValue.totalPrice,
        0,
      );
    },
    stockDiff(): number {
      return this.galaxusOrder.reduce(
        (previousValue, currentValue) => {
          const diff = currentValue.product.available >= currentValue.quantity ? 0 : 1;
          return previousValue + diff;
        },
        0,
      );
    },
  },
  methods: {
    loadFile(files: VaFile[]) : any {
      this.fileLoaded = true;

      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target === null) {
          return;
        }
        const readXml = e.target.result;

        const parser = new XMLParser();
        const jsonData = parser.parse(readXml as string);

        this.transformData(jsonData);
      };

      this.getStock()
        .then(() => {
          reader.readAsText(files[0] as any);
        });

      /* const xmlFile = readFileSync(files[0], 'utf8');
      const parser = new XMLParser();
      const jsonData = parser.parse(xmlFile);

      console.log(jsonData); */
    },
    getStock() {
      this.loading = true;

      const URLStock = '/etatstock';

      return axios.get(URLStock, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      })
        .then((data) => {
          this.stock = data.data.map((d: any) => {
            const catalogs = new Array<Catalog>();

            if (d.CatalogueIDS !== null) {
              const catalogIDs = d.CatalogueIDS.split(';');
              const catalogNames = d.CatalogueLibelles.split(';');
              let catalogEndDates = new Array<Date>();
              if (d.CatalogeDatesFins !== null) {
                catalogEndDates = d.CatalogeDatesFins.split(';');
              }
              for (let index = 0; index < catalogIDs.length; index += 1) {
                const catalog: Catalog = {
                  ID: catalogIDs[index],
                  name: catalogNames[index],
                  endDate: catalogEndDates[index],
                };
                catalogs.push(catalog);
              }
            }

            const prod: Product = {
              productID: Number(d.pid),
              variantID: Number(d.vid),
              releaseDate: new Date(d.dateSortie),
              incommingDate: new Date(d.dateArrivage),
              license: {
                ID: d.licence,
                FR: d.LicencesLibelleFR,
                JP: d.LicencesLibelleJP,
                EN: d.LicencesLibelleEN,
                DE: d.LicencesLibelleDE,
                IT: d.LicencesLibelleIT,
                master: d.LicencesMaitre,
              },
              manufacturer: {
                ID: d.fabricant,
                FR: d.FabricantsLibelleFR,
                JP: d.FabricantsLibelleJP,
              },
              price: d.pv,
              msrp: d.pvc,
              type: {
                ID: d.type,
                FR: d.TypesLibelleFR,
                JP: d.TypesLibelleJP,
                EN: d.TypesLibelleEN,
                DE: d.TypesLibelleIT,
                IT: d.TypesLibelleIT,
                master: d.TypesMaitre,
              },
              boxOf: d.boiteDe,
              packingSize: d.colisage,
              ean: d.ean,
              weight: d.poids,
              characters: d.Personnages !== null ? d.Personnages.split(',') : undefined,
              productPictures: d.PhotosProduit !== null ? d.PhotosProduit.split(',') : undefined,
              variantPictures: d.PhotosVariante !== null ? d.PhotosVariante.split(',') : undefined,
              nameFR: d.NomFR,
              nameEN: d.NomEN,
              nameDE: d.NomDE,
              nameIT: d.NomIT,
              catalog: catalogs,
              available: d.disponiblePositif,
              createdAt: new Date(d.VarianteCreatedAt),
              updatedAt: new Date(d.VarianteUpdatedAt),
            };

            return prod;
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => { this.loading = false; });
    },
    transformData(data: any) {
      const rows = data.Workbook.Worksheet.Table.Row;

      const transformedData = rows.slice(1).map((row:any) => {
        const gVarianteID = Number(row.Cell[0].Data.split('V')[1]);
        const foundProduct = this.stock.find((product) => product.variantID === gVarianteID);
        if (foundProduct === undefined) {
          return undefined;
        }
        const product: Product = foundProduct as Product;

        const gProduct: GalaxusProduct = {
          product,
          quantity: Number(row.Cell[4].Data),
          articleNumber: row.Cell[7].Data,
          currency: row.Cell[6].Data,
          supplierArticleNumber: row.Cell[1].Data,
          unitPrice: Number(row.Cell[3].Data),
          totalPrice: Number(row.Cell[5].Data),
        };
        return gProduct;
      });

      this.galaxusOrder = transformedData;
    },
    confirmOrder() {
      if (this.galaxusOrderNumber <= 0) {
        // eslint-disable-next-line no-useless-return
        return;
      }
      // Create new order
      this.loading = true;

      const requestData = JSON.stringify({
        alternativeID: this.galaxusOrderNumber,
        date: new Date().toISOString().split('T')[0],
        client: 12,
        reference: `Galaxus n° ${this.galaxusOrderNumber}`,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      };

      axios.post('/confirmationcommande', requestData, config)
        // eslint-disable-next-line consistent-return
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            const orderID = response.data;

            const postFunction = (
              url:string,
              configPost: any,
              data: any,
            ) => axios.post(url, data, configPost);

            const promiseArray = this.galaxusOrder.map((gProduct) => {
              const URL = '/confirmationcommandeligne';

              const configPost = {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.token}`,
                },
              };

              const postData = JSON.stringify({
                variante: gProduct.product.variantID,
                confirmationCommande: orderID,
                quantite: gProduct.quantity,
              });

              return postFunction(URL, configPost, postData);
            });
            // confirm each line to order
            return Promise.all(promiseArray);
          }
        })
        .then((response) => {
          this.$store.commit(
            'addMessage',
            {
              message: 'Commande validée',
              type: 'success',
            },
          );
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addMessage',
            {
              message: 'Échec de la création de la commande',
              type: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
