import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7619738c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "productList" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "productSidebar flex sm4 md3 lg2" }
const _hoisted_4 = { class: "sidebarBlock" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "sidebarBlock" }
const _hoisted_7 = { class: "sidebarBlock" }
const _hoisted_8 = { class: "checkboxDiv" }
const _hoisted_9 = { class: "sidebarBlock" }
const _hoisted_10 = { class: "checkboxDiv" }
const _hoisted_11 = { class: "sidebarBlock" }
const _hoisted_12 = { class: "checkboxDiv" }
const _hoisted_13 = { class: "sidebarBlock" }
const _hoisted_14 = { class: "checkboxDiv" }
const _hoisted_15 = { class: "productListMain flex md9 lg10 sm8" }
const _hoisted_16 = { class: "row va-spacing-x-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_select = _resolveComponent("va-select")!
  const _component_va_checkbox = _resolveComponent("va-checkbox")!
  const _component_va_scroll_container = _resolveComponent("va-scroll-container")!
  const _component_va_collapse = _resolveComponent("va-collapse")!
  const _component_ProductPreview = _resolveComponent("ProductPreview")!
  const _component_va_pagination = _resolveComponent("va-pagination")!
  const _component_va_inner_loading = _resolveComponent("va-inner-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.filter)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_va_button, {
                  class: "sidebarClear",
                  icon: "close",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearFilter()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Enlever les filtres ")
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectionsLicenses, (option) => {
                  return (_openBlock(), _createBlock(_component_va_button, {
                    key: option.ID,
                    size: "small",
                    icon: "close",
                    onClick: ($event: any) => (_ctx.removeSelectedLicence(option))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(option.FR), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectionsTypes, (option) => {
                  return (_openBlock(), _createBlock(_component_va_button, {
                    key: option.ID,
                    size: "small",
                    icon: "close",
                    onClick: ($event: any) => (_ctx.removeSelectedType(option))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(option.FR), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectionsManufacturers, (option) => {
                  return (_openBlock(), _createBlock(_component_va_button, {
                    key: option.ID,
                    size: "small",
                    icon: "close",
                    onClick: ($event: any) => (_ctx.removeSelectedManufacturer(option))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(option.FR), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectionsStock, (option) => {
                  return (_openBlock(), _createBlock(_component_va_button, {
                    key: option.id,
                    size: "small",
                    icon: "close",
                    onClick: ($event: any) => (_ctx.removeSelectedStock(option))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(option.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_va_input, {
            class: "mb-4",
            modelValue: _ctx.searchInput,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchInput) = $event)),
            label: "Rechercher",
            placeholder: "Rechercher"
          }, {
            appendInner: _withCtx(() => [
              _createVNode(_component_va_icon, { name: "search" })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createTextVNode(" Trier par: "),
          _createVNode(_component_va_select, {
            modelValue: _ctx.orderBy,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.orderBy) = $event)),
            options: _ctx.orderOptions,
            "text-by": "name",
            "value-by": "id",
            "track-by": "id"
          }, null, 8, ["modelValue", "options"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_va_collapse, {
            modelValue: _ctx.sidebar.stock,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sidebar.stock) = $event)),
            header: "Stock"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_va_scroll_container, {
                  vertical: "",
                  class: "checkboxScroll"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsStock, (option) => {
                      return (_openBlock(), _createBlock(_component_va_checkbox, {
                        key: option.id,
                        modelValue: _ctx.selectionsStock,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectionsStock) = $event)),
                        "array-value": option,
                        label: option.name,
                        class: "checkboxBlock"
                      }, null, 8, ["modelValue", "array-value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_va_collapse, {
            modelValue: _ctx.sidebar.license,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.sidebar.license) = $event)),
            header: "Licence"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_va_scroll_container, {
                  vertical: "",
                  class: "checkboxScroll"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsLicenses, (option) => {
                      return (_openBlock(), _createBlock(_component_va_checkbox, {
                        key: option.ID,
                        modelValue: _ctx.selectionsLicenses,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectionsLicenses) = $event)),
                        "array-value": option,
                        label: option.FR,
                        class: "checkboxBlock"
                      }, null, 8, ["modelValue", "array-value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_va_collapse, {
            modelValue: _ctx.sidebar.type,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.sidebar.type) = $event)),
            header: "Type"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_va_scroll_container, {
                  vertical: "",
                  class: "checkboxScroll"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsTypes, (option) => {
                      return (_openBlock(), _createBlock(_component_va_checkbox, {
                        key: option.ID,
                        modelValue: _ctx.selectionsTypes,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectionsTypes) = $event)),
                        "array-value": option,
                        label: option.FR,
                        class: "checkboxBlock"
                      }, null, 8, ["modelValue", "array-value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_va_collapse, {
            modelValue: _ctx.sidebar.manufacturer,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.sidebar.manufacturer) = $event)),
            header: "Fabricant"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_va_scroll_container, {
                  vertical: "",
                  class: "checkboxScroll"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsManufacturers, (option) => {
                      return (_openBlock(), _createBlock(_component_va_checkbox, {
                        key: option.ID,
                        modelValue: _ctx.selectionsManufacturers,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.selectionsManufacturers) = $event)),
                        "array-value": option,
                        label: option.FR,
                        class: "checkboxBlock"
                      }, null, 8, ["modelValue", "array-value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_va_inner_loading, { loading: _ctx.loading }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_16, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productPaginate, (product) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "flex md4 lg2",
                  key: product.variantID
                }, [
                  _createVNode(_component_ProductPreview, { product: product }, null, 8, ["product"])
                ]))
              }), 128))
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_va_pagination, {
                modelValue: _ctx.page,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.page) = $event)),
                "visible-pages": 5,
                "page-size": _ctx.numberPerPage,
                total: _ctx.productsSize,
                class: "paginationButtons"
              }, null, 8, ["modelValue", "page-size", "total"])
            ])
          ]),
          _: 1
        }, 8, ["loading"])
      ])
    ])
  ]))
}