<template>
  <div class="proVenteDirect">
    <VenteDirectList class="venteDirectList"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import VenteDirectList from '@/components/VenteDirectList.vue'; // @ is an alias to /src

const data = {
};

export default defineComponent({
  name: 'VenteDirect',
  data() {
    return data;
  },
  components: {
    VenteDirectList,
  },
});
</script>

<style>
</style>
