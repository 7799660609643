import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55167e24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "proProductDashboard" }
const _hoisted_2 = { class: "proContentDashboard" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "flex mb-2 md3 xs12" }
const _hoisted_5 = { class: "flex mb-2 md3 xs12" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardSidebar = _resolveComponent("DashboardSidebar")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_checkbox = _resolveComponent("va-checkbox")!
  const _component_va_radio = _resolveComponent("va-radio")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_image = _resolveComponent("va-image")!
  const _component_va_popover = _resolveComponent("va-popover")!
  const _component_va_chip = _resolveComponent("va-chip")!
  const _component_va_data_table = _resolveComponent("va-data-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DashboardSidebar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_va_input, {
          class: "flex mb-2 md3 xs12",
          placeholder: "Filtre...",
          modelValue: _ctx.inputFilterDebounced,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputFilterDebounced) = $event))
        }, null, 8, ["modelValue"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_va_checkbox, {
            class: "mb-3 d-block",
            label: "Only valid GTIN",
            modelValue: _ctx.filters.gtinCheckbox,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.gtinCheckbox) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_checkbox, {
            class: "mb-3 d-block",
            label: "Only in stock",
            modelValue: _ctx.filters.stockCheckbox,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters.stockCheckbox) = $event))
          }, null, 8, ["modelValue"]),
          _createTextVNode(" Catalogue Galaxus "),
          _createVNode(_component_va_radio, {
            modelValue: _ctx.filters.selectedGalaxusOption,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filters.selectedGalaxusOption) = $event)),
            option: _ctx.radioGalaxusOptions[0],
            color: "danger",
            label: "Hors"
          }, null, 8, ["modelValue", "option"]),
          _createVNode(_component_va_radio, {
            modelValue: _ctx.filters.selectedGalaxusOption,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filters.selectedGalaxusOption) = $event)),
            option: _ctx.radioGalaxusOptions[1],
            color: "warning",
            label: "Tous"
          }, null, 8, ["modelValue", "option"]),
          _createVNode(_component_va_radio, {
            modelValue: _ctx.filters.selectedGalaxusOption,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filters.selectedGalaxusOption) = $event)),
            option: _ctx.radioGalaxusOptions[2],
            color: "success",
            label: "Inclus"
          }, null, 8, ["modelValue", "option"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_va_button, {
            icon: "add",
            size: "small",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.addSelectedProductToGalaxus()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Ajouter au catalogue Galaxus ")
            ]),
            _: 1
          })
        ])
      ]),
      _createVNode(_component_va_data_table, {
        items: _ctx.filteredProducts,
        columns: _ctx.columns,
        loading: _ctx.loading,
        selectable: "",
        modelValue: _ctx.selectedProducts,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedProducts) = $event)),
        "items-track-by": "variantID",
        "select-mode": "multiple",
        striped: ""
      }, {
        "cell(pictures)": _withCtx(({ value }) => [
          _createVNode(_component_va_image, {
            contain: "",
            class: "cartProductImage",
            src: value.split(',')[0]
          }, null, 8, ["src"])
        ]),
        "cell(price)": _withCtx(({ value }) => [
          _createTextVNode(_toDisplayString(_ctx.monetaryFormat.format(value)), 1)
        ]),
        "cell(buyingprice)": _withCtx(({ value }) => [
          _createTextVNode(_toDisplayString(_ctx.monetaryFormat.format(value)), 1)
        ]),
        "cell(msrp)": _withCtx(({ value }) => [
          _createTextVNode(_toDisplayString(_ctx.monetaryFormat.format(value)), 1)
        ]),
        "cell(jpyPrice)": _withCtx(({ value }) => [
          _createTextVNode(_toDisplayString(_ctx.monetaryFormatJPY.format(value)), 1)
        ]),
        "cell(license)": _withCtx(({ value }) => [
          (value.length > 23)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                _createVNode(_component_va_popover, {
                  message: value,
                  placement: "left"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(value.substring(0,20)) + "... ", 1)
                  ]),
                  _: 2
                }, 1032, ["message"])
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(value), 1))
        ]),
        "cell(type)": _withCtx(({ value }) => [
          (value.length > 23)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                _createVNode(_component_va_popover, {
                  message: value,
                  placement: "left"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(value.substring(0,20)) + "... ", 1)
                  ]),
                  _: 2
                }, 1032, ["message"])
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(value), 1))
        ]),
        "cell(manufacturer)": _withCtx(({ value }) => [
          (value.length > 23)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                _createVNode(_component_va_popover, {
                  message: value,
                  placement: "right"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(value.substring(0,20)) + "... ", 1)
                  ]),
                  _: 2
                }, 1032, ["message"])
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(value), 1))
        ]),
        "cell(name)": _withCtx(({ value }) => [
          (value.length > 53)
            ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                _createVNode(_component_va_popover, {
                  message: value,
                  placement: "left"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(value.substring(0,50)) + "... ", 1)
                  ]),
                  _: 2
                }, 1032, ["message"])
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(value), 1))
        ]),
        "cell(catalog)": _withCtx(({ rowIndex }) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProducts[rowIndex].product.catalog, (item) => {
            return (_openBlock(), _createBlock(_component_va_chip, {
              flat: "",
              key: item.ID
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["items", "columns", "loading", "modelValue"])
    ])
  ]))
}