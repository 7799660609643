import { createStore } from 'vuex';
import VuexPersister from 'vuex-persister';
import axios from 'axios';
import { debounce } from 'lodash';
import User from '@/components/types/User';
import Address from '@/components/types/Address';

const vuexPersister = new VuexPersister({
  // ...your options
});

export default createStore({
  state: {
    user: {} as User,
    addresses: new Array<Address>(),
    selectedAddress: {} as Address,
    token: null,
    data: {
    },
    all: {
      loading: false,
      backgroundLoading: false,
      messages: null,
    },
  },
  getters: {
    token: (state) => state.token,
    isLoggedIn: (state) => state.token !== null,
    isAdmin: (state) => Number(state.user.admin) === 1,
    user: (state) => state.user,
    addresses: (state) => state.addresses,
    getSelectedAddress: (state) => state.selectedAddress,
    getAddressForId: (state) => (id:number) => state.addresses.find((add) => add.ID === id),
    loading: (state) => state.all.loading,
    backgroundLoading: (state) => state.all.backgroundLoading,
    message: (state) => state.all.messages,
  },
  mutations: {
    clearMessage(state): void {
      state.all.messages = null;
    },
    clearUser(state): void {
      state.user = {} as User;
      state.addresses = new Array<Address>();
      state.selectedAddress = {} as Address;
    },
    addMessage(state, payload): void {
      state.all.messages = payload;
    },
    setToken(state, payload): void {
      state.token = payload.token;
    },
    setUser(state, payload): void {
      const user:User = {
        ID: payload.id,
        username: payload.username,
        expiration: payload.expiration,
        token: payload.token,
        admin: payload.admin,
        company: {
          ID: payload.entreprise,
          name: payload.EntreprisesLIBELLE,
          discount: payload.EntreprisesRABAIS,
          subName: payload.EntreprisesSOUSNOM,
          address: payload.EntreprisesADRESSE,
          addressNo: payload.EntreprisesADRESSENO,
          npa: payload.EntreprisesNPA,
          city: payload.EntreprisesVILLE,
          country: {
            code: payload.EntreprisesPAYS,
            name: '',
          },
        },
      };

      const addresses: Array<Address> = payload.addresss.map((ad:any) => {
        const address:Address = {
          ID: ad.id,
          name: ad.libelle,
          title: ad.gentile,
          firstName: ad.prenom,
          lastName: ad.nom,
          address: ad.adresse,
          addressNo: ad.adresseNo,
          subAddress: ad.adresse2,
          subAddressNo: ad.adresse2No,
          npa: ad.NPA,
          city: ad.ville,
          country: ad.pays,
        };

        return address;
      });

      state.user = user;
      state.addresses = addresses;
      [state.selectedAddress] = state.addresses;
    },
    setLoading(state, payload) {
      state.all.loading = payload.loading;
    },
    setSelectedAddress(state, payload) {
      state.selectedAddress = payload;
    },
    setBackgroundLoading(state, payload) {
      state.all.backgroundLoading = payload.backgroundLoading;
    },
  },
  actions: {
    login({ commit, getters, dispatch }, user) {
      const requestData = JSON.stringify({
        username: user.username,
        password: user.password,
      });

      commit('setLoading', { loading: true });

      return axios.post('/authenticate', requestData)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            const token = response.data;
            commit('setToken', { token });
          }
          return dispatch('updateState');
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de connexion: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setLoading', { loading: false });
        });
    },
    updateState({ commit, getters }) {
      commit('setLoading', { loading: true });
      const debounced = debounce(() => axios.get('/me', {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      })
        .then((resp) => {
          if (resp.status === 200) {
            commit('setUser', resp.data);
            commit(
              'addMessage',
              {
                message: 'Mise à jour réussie',
                type: 'success',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de connexion: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setLoading', { loading: false });
        }), 300);

      return debounced();
    },
    reset({ commit, getters }, body) {
      const requestData = JSON.stringify({
        username: body.username,
        os: body.os,
        browser: body.browser,
      });

      commit('setLoading', { loading: true });

      return axios.post('/authenticate/reset', requestData)
        .then((response) => {
          if (response.status === 200) {
            commit(
              'addMessage',
              {
                message: 'Demande de réinitialisation envoyée',
                type: 'success',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de la demande de réinitialisation: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setLoading', { loading: false });
        });
    },
    changePassword({ commit, getters }, body) {
      const requestData = JSON.stringify({
        token: body.token,
        password: body.password,
      });

      commit('setLoading', { loading: true });

      return axios.post('/authenticate/changepassword', requestData)
        .then((response) => {
          if (response.status === 200) {
            commit(
              'addMessage',
              {
                message: 'Mot de passe changé',
                type: 'success',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec du changement de mot de passe: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setLoading', { loading: false });
        });
    },
    newAccount({ commit, getters }, body) {
      const requestData = JSON.stringify({
        company: body.company,
        email: body.email,
        website: body.website,
        firstName: body.firstName,
        lastName: body.lastName,
        address: body.address,
        npa: body.npa,
        city: body.city,
        country: body.country,
      });

      console.log(requestData);

      commit('setLoading', { loading: true });

      return axios.post('/contact/newaccount', requestData)
        .then((response) => {
          if (response.status === 200) {
            commit(
              'addMessage',
              {
                message: 'Demande envoyée',
                type: 'success',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de la demande: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setLoading', { loading: false });
        });
    },
    setSelectedAddress({ commit, getters }, body) {
      commit('setSelectedAddress', body);
    },
    clearMessage({ commit }, body) {
      commit('clearMessage');
    },
    logout({ commit }, body) {
      commit('setToken', { token: null });
      commit('clearUser');
    },
  },
  modules: {
    // TODO split everything in module
    // TODO use vuex-ORM when version is 1.0
  },
  plugins: [vuexPersister.persist], // integrate the plugin
});
