
import { defineComponent } from 'vue';
import axios from 'axios';
import Cart from '@/components/types/Cart';
import Product from '@/components/types/Product';
import ProductWithQuantity from '@/components/types/ProductWithQuantite';
import Address from '@/components/types/Address';
import ProductCart from '@/components/types/ProductCart';

export default defineComponent({
  name: 'CartView',
  data() {
    const columns = [
      {
        key: 'product.product.variantPictures',
        name: 'pictures',
        sortable: true,
        label: 'Image',
      },
      { key: 'product.product.ean', sortable: true, label: 'EAN' },
      { key: 'product.product.nameFR', sortable: false, label: 'Nom' },
      { key: 'quantity', sortable: true, label: 'Quantité' },
      {
        key: 'product.product.price', name: 'price', sortable: true, label: 'Prix (HT)',
      },
      { key: 'totalPrice', sortable: true, label: 'Prix Total (HT)' },
      { key: 'actions', width: 80 },
    ];
    const monetaryFormat = new Intl.NumberFormat('fr-CH', {
      style: 'currency',
      currency: 'CHF',

      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers
      // , but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return {
      columns,
      monetaryFormat,
      loading: false,
      cartProducts: new Array<Cart>(),
    };
  },
  components: {
  },
  created() {
    this.getCartData();
  },
  methods: {
    getCartData() {
      this.loading = true;

      return axios.get('/panier', {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            const data = new Array<Cart>();

            Object.entries(response.data).forEach(([key, value]) => {
              const prods: Array<ProductCart> = (value as any).map((d: any) => {
                const prod: Product = {
                  productID: d.pid,
                  variantID: d.vid,
                  releaseDate: new Date(d.dateSortie),
                  incommingDate: new Date(d.dateArrivage),
                  license: {
                    ID: d.licence,
                    FR: d.LicencesLibelleFR,
                    JP: d.LicencesLibelleJP,
                    EN: d.LicencesLibelleEN,
                    DE: d.LicencesLibelleDE,
                    IT: d.LicencesLibelleIT,
                    master: d.LicencesMaitre,
                  },
                  manufacturer: {
                    ID: d.fabricant,
                    FR: d.FabricantsLibelleFR,
                    JP: d.FabricantsLibelleJP,
                  },
                  price: d.pv,
                  msrp: d.pvc,
                  type: {
                    ID: d.type,
                    FR: d.TypesLibelleFR,
                    JP: d.TypesLibelleJP,
                    EN: d.TypesLibelleEN,
                    DE: d.TypesLibelleIT,
                    IT: d.TypesLibelleIT,
                    master: d.TypesMaitre,
                  },
                  boxOf: Number(d.boiteDe),
                  packingSize: d.colisage,
                  ean: d.ean,
                  weight: d.poids,
                  characters: d.Personnages !== null ? d.Personnages.split(',') : undefined,
                  productPictures: d.PhotosProduit !== null ? d.PhotosProduit.split(',') : undefined,
                  variantPictures: d.PhotosVariante !== null ? d.PhotosVariante.split(',') : undefined,
                  nameFR: d.NomFR,
                  nameEN: d.NomEN,
                  nameDE: d.NomDE,
                  nameIT: d.NomIT,
                  catalog: [],
                  available: d.disponiblePositif,
                  createdAt: new Date(d.VarianteCreatedAt),
                  updatedAt: new Date(d.VarianteUpdatedAt),
                };

                const prodWithQty: ProductWithQuantity = {
                  quantity: d.PanierQuantite,
                  product: prod,
                };

                const prodCart:ProductCart = {
                  product: prodWithQty,
                  cartID: d.PanierID,
                  createdAt: new Date(d.PanierCreatedAt),
                  updatedAt: new Date(d.PanierUpdatedAt),
                };

                return prodCart;
              });

              const add: Address = this.$store.getters.getAddressForId(key);

              data.push({ address: add, products: prods });
            });
            this.cartProducts = data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItemById(cartID: number) {
      this.loading = true;

      if (this.cartProducts !== undefined) {
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        };

        axios.delete(`/panier/${cartID}`, config)
          .then((response) => {
            console.log(response);
            if (response.status === 200 || response.status === 201) {
              this.$store.commit(
                'addMessage',
                {
                  message: 'Panier modifié',
                  type: 'success',
                },
              );
            }
          }).catch((error) => {
            console.log(error);
            this.$store.commit(
              'addMessage',
              {
                message: 'Échec de la modification',
                type: 'danger',
              },
            );
          })
          .finally(() => {
            this.getCartData();
          });
      }
    },
    updateItemById(cartID: number, newQuantity: number, variant: number) {
      this.loading = true;

      const requestData = JSON.stringify({
        quantite: newQuantity,
        variante: variant,
        client: this.$store.getters.getSelectedAddress.ID,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      };

      axios.put(`/panier/${cartID}`, requestData, config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log('successfully edited to the cart');
            console.log(response);
            this.$store.commit(
              'addMessage',
              {
                message: 'Produit modifié',
                type: 'success',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addMessage',
            {
              message: 'Échec de la modification',
              type: 'danger',
            },
          );
        })
        .finally(() => {
          console.log('success message');
          this.loading = false;
        });
    },
    validateCart() {
      this.loading = true;
      const requestData = JSON.stringify({
        cart: this.cartProducts,
      });
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      };

      axios.post('/panier/validate', requestData, config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log(response);
            this.$store.commit(
              'addMessage',
              {
                message: 'Commande validée',
                type: 'success',
              },
            );
            this.$router.push({
              name: 'Espace Pro - Mes commandes',
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addMessage',
            {
              message: 'Échec de la commande',
              type: 'danger',
            },
          );
          return this.getCartData();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
