
import Address from '@/components/types/Address';
import Product from '@/components/types/Product';
import ProductWithRemainder from '@/components/types/ProductWithRemainder';
import RemainderList from '@/components/types/RemainderList';
import AccountSidebar from '@/components/AccountSidebar.vue';
import User from '@/components/types/User';
import axios from 'axios';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AccountView',
  data() {
    const columns = [
      {
        key: 'product.variantPictures',
        name: 'pictures',
        sortable: true,
        label: 'Image',
      },
      { key: 'product.ean', sortable: true, label: 'EAN' },
      { key: 'product.manufacturer.FR', sortable: true, label: 'Fabricant' },
      { key: 'product.license.FR', sortable: true, label: 'Licence' },
      { key: 'product.type.FR', sortable: true, label: 'Type' },
      { key: 'product.nameFR', sortable: false, label: 'Nom' },
      { key: 'remainder', sortable: true, label: 'Reliquat' },
      {
        key: 'product.price', name: 'price', sortable: true, label: 'Prix (HT)',
      },
      {
        key: 'product.msrp', name: 'msrp', sortable: true, label: 'Prix de vente conseillé',
      },
    ];
    const monetaryFormat = new Intl.NumberFormat('fr-CH', {
      style: 'currency',
      currency: 'CHF',

      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers
      // , but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return {
      columns,
      monetaryFormat,
      loading: false,
      remainderList: new Array<RemainderList>(),
      permalink: {
        catalog: false,
        csv: false,
        xlsx: false,
        loading: false,
      },
    };
  },
  components: {
    AccountSidebar,
  },
  computed: {
    user(): User {
      return this.$store.getters.user;
    },
    addresses(): Array<Address> {
      return this.$store.getters.addresses;
    },
  },
  created() {
    this.loading = true;

    return axios.get('/confirmationcommande/reliquat', {
      headers: {
        Authorization: `Bearer ${this.$store.getters.token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          const data = new Array<RemainderList>();

          Object.entries(response.data).forEach(([key, value]) => {
            const prods: Array<ProductWithRemainder> = (value as any).map((d: any) => {
              const prod: Product = {
                productID: d.pid,
                variantID: d.vid,
                releaseDate: new Date(d.dateSortie),
                incommingDate: new Date(d.dateArrivage),
                license: {
                  ID: d.licence,
                  FR: d.LicencesLibelleFR,
                  JP: d.LicencesLibelleJP,
                  EN: d.LicencesLibelleEN,
                  DE: d.LicencesLibelleDE,
                  IT: d.LicencesLibelleIT,
                  master: d.LicencesMaitre,
                },
                manufacturer: {
                  ID: d.fabricant,
                  FR: d.FabricantsLibelleFR,
                  JP: d.FabricantsLibelleJP,
                },
                price: d.pv,
                msrp: d.pvc,
                type: {
                  ID: d.type,
                  FR: d.TypesLibelleFR,
                  JP: d.TypesLibelleJP,
                  EN: d.TypesLibelleEN,
                  DE: d.TypesLibelleIT,
                  IT: d.TypesLibelleIT,
                  master: d.TypesMaitre,
                },
                boxOf: Number(d.boiteDe),
                packingSize: d.colisage,
                ean: d.ean,
                weight: d.poids,
                characters: d.Personnages !== null ? d.Personnages.split(',') : undefined,
                productPictures: d.PhotosProduit !== null ? d.PhotosProduit.split(',') : undefined,
                variantPictures: d.PhotosVariante !== null ? d.PhotosVariante.split(',') : undefined,
                nameFR: d.NomFR,
                nameEN: d.NomEN,
                nameDE: d.NomDE,
                nameIT: d.NomIT,
                catalog: [],
                available: d.disponiblePositif,
                createdAt: new Date(d.VarianteCreatedAt),
                updatedAt: new Date(d.VarianteUpdatedAt),
              };

              const prodWithQty: ProductWithRemainder = {
                product: prod,
                ordered: d.CommandeQuantite,
                orders: d.CommandeNo !== null ? d.CommandeNo.split(',') : undefined,
                cancelled: d.AnnulationQuantite,
                cancellation: d.Annulations !== null ? d.Annulations.split(',') : undefined,
                delivered: d.LivraisonQuantite,
                deliveries: d.Livraisons !== null ? d.Livraisons.split(',') : undefined,
                remainder: d.reliquat,
              };

              return prodWithQty;
            });

            const add: Address = this.$store.getters.getAddressForId(key);

            data.push({ address: add, remainders: prods });
          });
          this.remainderList = data;
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    getHeaderForRemainder(address: Address) {
      return `En attente pour ${address.name},${address.address} ${address.addressNo}, ${address.npa} ${address.city}`;
    },
    downloadCatalogue() : void {
      this.permalink.loading = true;

      axios.get(`/permalink/${this.$store.getters.token}/stockcatalogue`, {
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'JAPORT_catalogue.xlsx');
        document.body.appendChild(fileLink);

        fileLink.click();
      }).catch((error: any) => {
        console.log(error);
      }).finally(() => {
        this.permalink.loading = false;
      });
    },
    downloadStockXLS() : void {
      this.permalink.loading = true;

      axios.get(`/permalink/${this.$store.getters.token}/stockxls`, {
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'JAPORT_stock.xlsx');
        document.body.appendChild(fileLink);

        fileLink.click();
      }).catch((error: any) => {
        console.log(error);
      }).finally(() => {
        this.permalink.loading = false;
      });
    },
    downloadStockCSV() : void {
      this.permalink.loading = true;

      axios.get(`/permalink/${this.$store.getters.token}/stockcsv`, {
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'JAPORT_stock.csv');
        document.body.appendChild(fileLink);

        fileLink.click();
      }).catch((error: any) => {
        console.log(error);
      }).finally(() => {
        this.permalink.loading = false;
      });
    },
    rowClick(event: any) {
      const ProductClicked: Product = event.item.product;
      this.$router.push({
        name: 'Espace Pro - Detail Produits',
        params: { id: ProductClicked.variantID },
      });
    },
    copyCatalogPermalinkToClipboard() {
      this.permalink.catalog = true;
      this.copyToClipboard(`https://api.japort.ch/permalink/${this.$store.getters.token}/stockcatalogue`);
    },
    copyCSVPermalinkToClipboard() {
      this.permalink.csv = true;
      this.copyToClipboard(`https://api.japort.ch/permalink/${this.$store.getters.token}/stockcsv`);
    },
    copyXLSXPermalinkToClipboard() {
      this.permalink.xlsx = true;
      this.copyToClipboard(`https://api.japort.ch/permalink/${this.$store.getters.token}/stockxls`);
    },
    async copyToClipboard(value: string) {
      try {
        await navigator.clipboard.writeText(value);
        this.$store.commit(
          'addMessage',
          {
            message: 'Copié',
            type: 'success',
          },
        );
      } catch ($e) {
        this.$store.commit(
          'addMessage',
          {
            message: 'Echec de l\'action',
            type: 'danger',
          },
        );
      }
    },
  },
});
