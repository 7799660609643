
import { defineComponent } from 'vue';
import ClientSidebar from '@/components/ClientSidebar.vue';
import axios from 'axios';
import Delivery from '@/components/types/Delivery';
import TypeUtil from '@/components/types/TypeUtil';
import State from '@/components/types/State';
import ProductWithRemainder from '@/components/types/ProductWithRemainder';
import Product from '@/components/types/Product';
import Address from '@/components/types/Address';
import DeliveryList from '@/components/types/DeliveryList';
import DeliveryWithProducts from '@/components/types/DeliveryWithProducts';
import ProductOrder from '@/components/types/ProductOrder';

export default defineComponent({
  name: 'ClientsDeliveries',
  data() {
    const columnsRemainder = [
      {
        key: 'actions', sortable: false, label: 'Actions', width: 80,
      },
      {
        key: 'remainder', name: 'remainder', sortable: true, label: 'Reliquat',
      },
      {
        key: 'availability', sortable: true, label: 'Dispo',
      },
      {
        key: 'product.variantPictures',
        name: 'pictures',
        sortable: true,
        label: 'Image',
      },
      {
        key: 'product.ean', name: 'ean', sortable: true, label: 'EAN',
      },
      { key: 'product.manufacturer.FR', sortable: true, label: 'Fabricant' },
      { key: 'product.nameFR', sortable: false, label: 'Nom' },
    ];

    const columnsDelivery = [
      {
        key: 'delivered', name: 'delivered', sortable: true, label: 'Livré',
      },
      {
        key: 'product.variantPictures',
        name: 'pictures',
        sortable: true,
        label: 'Image',
      },
      {
        key: 'product.ean', name: 'ean', sortable: true, label: 'EAN',
      },
      { key: 'product.manufacturer.FR', sortable: true, label: 'Fabricant' },
      { key: 'product.nameFR', sortable: false, label: 'Nom' },
      {
        key: 'actions', sortable: true, label: '',
      },
    ];

    const columnsDeliveries = [
      { key: 'date', sortable: true, label: 'Date' },
      { key: 'destination.name', sortable: true, label: 'Destination' },
      { key: 'trackingNumber', sortable: false, label: 'N° Tracking' },
      { key: 'reference', sortable: false, label: 'Reference' },
      { key: 'state', sortable: true, label: 'État' },
      { key: 'actions', width: 80 },
    ];

    const lorem = 'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit, amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt, ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit, qui in ea voluptate velit esse, quam nihil molestiae consequatur, vel illum, qui dolorem eum fugiat, quo voluptas nulla pariatur? At vero eos et accusamus et iusto odio dignissimos ducimus, qui blanditiis praesentium voluptatum deleniti atque corrupti, quos dolores et quas molestias excepturi sint, obcaecati cupiditate non provident, similique sunt in culpa, qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio, cumque nihil impedit, quo minus id, quod maxime placeat, facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet, ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.';

    const monetaryFormat = new Intl.NumberFormat('fr-CH', {
      style: 'currency',
      currency: 'CHF',

      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers
      // , but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const dateFormat = new Intl.DateTimeFormat('fr-CH');

    return {
      lorem,
      DRAFT: State.DRAFT,
      splitActions: [
        { value: 'vertical', icon: 'vertical_split' },
        { value: 'horizontal', icon: 'horizontal_split' },
      ],
      splitView: 'horizontal',
      newItem: {
        date: new Date(),
        destination: {} as any,
        tracking: '',
        reference: '',
        state: State.DRAFT,
      },
      keyPressed: '',
      timeout: 0,
      columnsRemainder,
      columnsDelivery,
      columnsDeliveries,
      monetaryFormat,
      dateFormat,
      loading: false,
      remainderList: new Array<ProductWithRemainder>(),
      deliveryList: new Array<Delivery>(),
      delivery: {} as DeliveryList,
    };
  },
  components: {
    ClientSidebar,
  },
  computed: {
    routeId() {
      return this.$route.params.id !== undefined ? Number(this.$route.params.id) : undefined;
    },
    addressOptions() {
      return this.$store.getters.addresses;
    },
    stateOptions() {
      return Object.values(State).filter((v) => Number.isNaN(Number(v)));
    },
    deliveryDelivery(): any {
      return this.delivery.deliveries[0].delivery;
    },
    deliveryProducts(): any {
      if (this.delivery.deliveries[0].products === undefined) {
        return new Array<ProductOrder>();
      }
      return this.delivery.deliveries[0].products;
    },
    deliveryAddressName(): any {
      return this.delivery.address.name;
    },
    deliveryAmount(): number {
      if (this.routeId === undefined && this.deliveryProducts.length <= 0) {
        return 0;
      }
      return this.deliveryProducts.reduce(
        (previousValue: number, currentValue: any) => previousValue
                      + Number((currentValue.delivered
                      * currentValue.product.price).toFixed(2)),
        0,
      );
    },
  },
  watch: {
    $route(to, from) {
      if (this.routeId !== undefined) {
      // load one incomming in particular
        this.getDelivery(this.routeId);
      } else {
        this.getDeliveries();
      }
    },
  },
  mounted() {
    window.addEventListener('keypress', (e) => {
      if (e.key === 'Enter') {
        this.addBarcode(Number(this.keyPressed));
        this.keyPressed = '';
      } else {
        this.keyPressed += e.key;
      }
    });
    if (this.routeId !== undefined) {
      // load one incomming in particular
      this.getDelivery(this.routeId);
    } else {
      this.getDeliveries();
    }
  },
  methods: {
    addBarcode(barcode: number) {
      console.log(`Add Product with barcode : ${barcode}`);

      // found product with the same barcode
      /* const found = this.remainderList.find(
        (remainder) => remainder.product.ean === barcode,
      ); */
      const foundIndex = this.remainderList.findIndex(
        (remainder) => remainder.product.ean === barcode,
      );

      // if not found => error
      if (foundIndex < 0) {
        // toast error
        this.$store.commit(
          'addMessage',
          {
            message: 'Produit Inconnu',
            type: 'danger',
          },
        );
      } else {
        // if foud => addClick(product)
        this.addClick(this.remainderList[foundIndex] as ProductWithRemainder);
      }
    },
    getDelivery(ID:number) {
      this.loading = true;

      return axios.get(`/livraisonclientproduit/${ID}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            Object.entries(response.data).forEach(([key, value]) => {
              const add: Address = this.$store.getters.getAddressForId(key);
              const dels = new Array<DeliveryWithProducts>();

              Object.entries((value as any)).forEach(([keyOrderNumber, valueProduct]) => {
                let prods: Array<ProductOrder> = new Array<ProductOrder>();
                if ((valueProduct as any)[0].vid !== undefined) {
                  prods = (valueProduct as any).map((d: any) => {
                    const prod: Product = {
                      productID: d.pid,
                      variantID: d.vid,
                      releaseDate: new Date(d.dateSortie),
                      incommingDate: new Date(d.dateArrivage),
                      license: {
                        ID: d.licence,
                        FR: d.LicencesLibelleFR,
                        JP: d.LicencesLibelleJP,
                        EN: d.LicencesLibelleEN,
                        DE: d.LicencesLibelleDE,
                        IT: d.LicencesLibelleIT,
                        master: d.LicencesMaitre,
                      },
                      manufacturer: {
                        ID: d.fabricant,
                        FR: d.FabricantsLibelleFR,
                        JP: d.FabricantsLibelleJP,
                      },
                      price: d.pv,
                      msrp: d.pvc,
                      type: {
                        ID: d.type,
                        FR: d.TypesLibelleFR,
                        JP: d.TypesLibelleJP,
                        EN: d.TypesLibelleEN,
                        DE: d.TypesLibelleIT,
                        IT: d.TypesLibelleIT,
                        master: d.TypesMaitre,
                      },
                      boxOf: Number(d.boiteDe),
                      packingSize: d.colisage,
                      ean: d.ean,
                      weight: d.poids,
                      characters: d.Personnages !== null ? d.Personnages.split(',') : undefined,
                      productPictures: d.PhotosProduit !== null ? d.PhotosProduit.split(',') : undefined,
                      variantPictures: d.PhotosVariante !== null ? d.PhotosVariante.split(',') : undefined,
                      nameFR: d.NomFR,
                      nameEN: d.NomEN,
                      nameDE: d.NomDE,
                      nameIT: d.NomIT,
                      catalog: [],
                      available: d.disponiblePositif,
                      createdAt: new Date(d.VarianteCreatedAt),
                      updatedAt: new Date(d.VarianteUpdatedAt),
                    };

                    const prodOrder: ProductOrder = {
                      ordered: d.CommandeQuantite,
                      product: prod,
                      delivered: d.LivraisonQuantite,
                      deliveriesIDS: d.LivraisonIDS.split('&'),
                    };

                    return prodOrder;
                  });
                }
                const delData: Delivery = {
                  number: Number(keyOrderNumber),
                  date: new Date((valueProduct as any)[0].LivraisonDate),
                  destination: this.$store.getters.getAddressForId(
                    Number((valueProduct as any)[0].LivraisonDestination),
                  ),
                  trackingNumber: (valueProduct as any)[0].LivraisonReferenceEnvoi,
                  reference: (valueProduct as any)[0].LivraisonReference,
                  state: TypeUtil.parseState(Number((valueProduct as any)[0].LivraisonState)),
                };

                dels.push({
                  delivery: delData,
                  products: prods,
                });
              });

              this.delivery = {
                address: add,
                deliveries: dels.sort(
                  (
                    a: DeliveryWithProducts,
                    b: DeliveryWithProducts,
                  ) => b.delivery.number - a.delivery.number,
                ),
              };
            });
          }
          return this.getReliquat(this.delivery.address.ID);
        })
        .then((response) => {
          // console.log(response);
          console.log('end request');
          console.log(this.delivery);
          console.log(this.remainderList);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });

      // Get reliquat for destination address // new api point ?
      // Dans tous les casfaire un reliquatcontroller avec la méthode getReliquatFor(AddressNumber),
      // changer confirmation de commande -> Reliquat pour utiliser ce nouveau controller
      // version standard ne prend en compte que les livraisons non draft
      // version admin prends dans une colonne séparée les livraisons en draft
      // Get delivery lines for ID
    },
    getDeliveryRequest(ID:number) {
      return axios.get(`/livraisonclientproduit/${ID}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            Object.entries(response.data).forEach(([key, value]) => {
              const add: Address = this.$store.getters.getAddressForId(key);
              const dels = new Array<DeliveryWithProducts>();

              Object.entries((value as any)).forEach(([keyOrderNumber, valueProduct]) => {
                const prods: Array<ProductOrder> = (valueProduct as any).map((d: any) => {
                  const prod: Product = {
                    productID: d.pid,
                    variantID: d.vid,
                    releaseDate: new Date(d.dateSortie),
                    incommingDate: new Date(d.dateArrivage),
                    license: {
                      ID: d.licence,
                      FR: d.LicencesLibelleFR,
                      JP: d.LicencesLibelleJP,
                      EN: d.LicencesLibelleEN,
                      DE: d.LicencesLibelleDE,
                      IT: d.LicencesLibelleIT,
                      master: d.LicencesMaitre,
                    },
                    manufacturer: {
                      ID: d.fabricant,
                      FR: d.FabricantsLibelleFR,
                      JP: d.FabricantsLibelleJP,
                    },
                    price: d.pv,
                    msrp: d.pvc,
                    type: {
                      ID: d.type,
                      FR: d.TypesLibelleFR,
                      JP: d.TypesLibelleJP,
                      EN: d.TypesLibelleEN,
                      DE: d.TypesLibelleIT,
                      IT: d.TypesLibelleIT,
                      master: d.TypesMaitre,
                    },
                    boxOf: Number(d.boiteDe),
                    packingSize: d.colisage,
                    ean: d.ean,
                    weight: d.poids,
                    characters: d.Personnages !== null ? d.Personnages.split(',') : undefined,
                    productPictures: d.PhotosProduit !== null ? d.PhotosProduit.split(',') : undefined,
                    variantPictures: d.PhotosVariante !== null ? d.PhotosVariante.split(',') : undefined,
                    nameFR: d.NomFR,
                    nameEN: d.NomEN,
                    nameDE: d.NomDE,
                    nameIT: d.NomIT,
                    catalog: [],
                    available: d.disponiblePositif,
                    createdAt: new Date(d.VarianteCreatedAt),
                    updatedAt: new Date(d.VarianteUpdatedAt),
                  };

                  const prodOrder: ProductOrder = {
                    ordered: d.CommandeQuantite,
                    product: prod,
                    delivered: d.LivraisonQuantite,
                  };

                  return prodOrder;
                });
                const delData: Delivery = {
                  number: Number(keyOrderNumber),
                  date: new Date((valueProduct as any)[0].LivraisonDate),
                  destination: this.$store.getters.getAddressForId(
                    Number((valueProduct as any)[0].LivraisonDestination),
                  ),
                  trackingNumber: (valueProduct as any)[0].LivraisonReferenceEnvoi,
                  reference: (valueProduct as any)[0].LivraisonReference,
                  state: TypeUtil.parseState(Number((valueProduct as any)[0].LivraisonState)),
                };

                dels.push({
                  delivery: delData,
                  products: prods,
                });
              });

              this.delivery = {
                address: add,
                deliveries: dels.sort(
                  (
                    a: DeliveryWithProducts,
                    b: DeliveryWithProducts,
                  ) => b.delivery.number - a.delivery.number,
                ),
              };
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReliquat(ID:number) {
      // ID is the delivery address ID !!!
      return axios.get(`/reliquat/${ID}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            const prods: Array<ProductWithRemainder> = (response.data as any).map((d: any) => {
              const prod: Product = {
                productID: d.pid,
                variantID: d.vid,
                releaseDate: new Date(d.dateSortie),
                incommingDate: new Date(d.dateArrivage),
                license: {
                  ID: d.licence,
                  FR: d.LicencesLibelleFR,
                  JP: d.LicencesLibelleJP,
                  EN: d.LicencesLibelleEN,
                  DE: d.LicencesLibelleDE,
                  IT: d.LicencesLibelleIT,
                  master: d.LicencesMaitre,
                },
                manufacturer: {
                  ID: d.fabricant,
                  FR: d.FabricantsLibelleFR,
                  JP: d.FabricantsLibelleJP,
                },
                price: d.pv,
                msrp: d.pvc,
                type: {
                  ID: d.type,
                  FR: d.TypesLibelleFR,
                  JP: d.TypesLibelleJP,
                  EN: d.TypesLibelleEN,
                  DE: d.TypesLibelleIT,
                  IT: d.TypesLibelleIT,
                  master: d.TypesMaitre,
                },
                boxOf: Number(d.boiteDe),
                packingSize: d.colisage,
                ean: d.ean,
                weight: d.poids,
                characters: d.Personnages !== null ? d.Personnages.split(',') : undefined,
                productPictures: d.PhotosProduit !== null ? d.PhotosProduit.split(',') : undefined,
                variantPictures: d.PhotosVariante !== null ? d.PhotosVariante.split(',') : undefined,
                nameFR: d.NomFR,
                nameEN: d.NomEN,
                nameDE: d.NomDE,
                nameIT: d.NomIT,
                catalog: [],
                available: d.disponiblePositif,
                createdAt: new Date(d.VarianteCreatedAt),
                updatedAt: new Date(d.VarianteUpdatedAt),
              };

              const prodWithQty: ProductWithRemainder = {
                product: prod,
                ordered: d.CommandeQuantite,
                orders: d.CommandeNo !== null ? d.CommandeNo.split(',') : undefined,
                cancelled: d.AnnulationQuantite,
                cancellation: d.Annulations !== null && d.Annulations !== undefined ? d.Annulations.split(',') : undefined,
                delivered: d.LivraisonQuantite,
                deliveries: d.Livraisons !== null ? d.Livraisons.split(',') : undefined,
                availability: d.disponibilité,
                remainder: d.reliquat,
              };

              return prodWithQty;
            });

            this.remainderList = prods;
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    },
    getDeliveries() {
      this.loading = true;

      axios.get('/livraisonclient', {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.deliveryList = response.data.map((d: any) => {
              const delivery: Delivery = {
                number: d.id,
                date: new Date(d.dateEnvoi),
                destination: this.$store.getters.getAddressForId(
                  d.destination,
                ),
                reference: d.reference,
                trackingNumber: d.referenceEnvoi,
                state: TypeUtil.parseState(Number(d.state)),
              };
              return delivery;
            }).sort((a: Delivery, b: Delivery) => b.number - a.number);
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    rowClickDeliveries(event: any) {
      const deliveryClicked: Delivery = event.item;

      this.$router.push({
        name: 'Espace Pro - Livraison Clients',
        params: { id: deliveryClicked.number },
      });
    },
    stateName(index: number) {
      switch (this.deliveryList[index].state) {
        case State.DRAFT: return 'draft';
        case State.PUBLIC: return 'public';
        case State.ARCHIVED: return 'folder';
        case State.UNKNOWN: return 'question_mark';
        default: return 'question_mark';
      }
    },
    addNewDelivery() {
      // CRUD method
      console.log(this.newItem);

      this.loading = true;

      const requestData = JSON.stringify({
        dateEnvoi: this.newItem.date.toISOString().split('T')[0],
        referenceEnvoi: this.newItem.tracking,
        reference: this.newItem.reference,
        destination: this.newItem.destination.ID,
        state: 1,
      });

      console.log(requestData);

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      };

      axios.post('/livraisonclient', requestData, config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log(response);
            this.$store.commit(
              'addMessage',
              {
                message: 'Livraison ajouté',
                type: 'success',
              },
            );
          }
          this.getDeliveries();
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addMessage',
            {
              message: 'Échec de l\'ajout',
              type: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addClick(prod: ProductWithRemainder) {
      this.loading = true;

      const requestData = JSON.stringify({
        variante: prod.product.variantID,
        quantite: prod.product.boxOf,
        livraison: this.routeId,
      });

      console.log(requestData);

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      };

      axios.post('/livraisonclientproduit', requestData, config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log(response);
            this.$store.commit(
              'addMessage',
              {
                message: 'Livraison modifié',
                type: 'success',
              },
            );
          }
          if (this.routeId !== undefined) {
            this.getDelivery(this.routeId);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addMessage',
            {
              message: 'Échec de la modification',
              type: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeClick(prod: ProductOrder) {
      this.loading = true;

      const deleteFunction = (url:string) => axios.delete(url, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      });

      if (prod.deliveriesIDS === undefined) {
        return;
      }
      const promiseArray = prod.deliveriesIDS
        .map((id) => deleteFunction(`/livraisonclientligne/${id}`));

      Promise.all(promiseArray)
        .then((data) => {
          if (this.routeId !== undefined) {
            this.getDelivery(this.routeId);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addMessage',
            {
              message: 'Échec de la modification',
              type: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addAllClick(prod: ProductWithRemainder) {
      this.loading = true;

      const requestData = JSON.stringify({
        variante: prod.product.variantID,
        quantite: prod.remainder,
        livraison: this.routeId,
      });

      console.log(requestData);

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      };

      axios.post('/livraisonclientproduit', requestData, config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log(response);
            this.$store.commit(
              'addMessage',
              {
                message: 'Arrivage modifié',
                type: 'success',
              },
            );
          }
          if (this.routeId !== undefined) {
            this.getDelivery(this.routeId);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addMessage',
            {
              message: 'Échec de la modification',
              type: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    wait() {
      // router push to no deliveryNo
      this.getDeliveries();
      this.$router.push({
        name: 'Espace Pro - Livraisons Clients',
      });
    },
    refresh() {
      if (this.routeId !== undefined) {
      // load one incomming in particular
        this.getDelivery(this.routeId);
      } else {
        this.getDeliveries();
      }
    },
    edit() {
      // put request to set delivery to public
      this.loading = true;

      const requestData = JSON.stringify({
        state: 1,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      };

      axios.put(`/livraisonclient/${this.routeId}`, requestData, config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log(response);
            this.$store.commit(
              'addMessage',
              {
                message: 'Livraison modifié',
                type: 'success',
              },
            );
          }
          if (this.routeId !== undefined) {
            this.getDelivery(this.routeId);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addMessage',
            {
              message: 'Échec de la modification',
              type: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    done() {
      // put request to set delivery to public
      this.loading = true;

      const requestData = JSON.stringify({
        dateEnvoi: new Date().toISOString().split('T')[0],
        state: 2,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      };

      axios.put(`/livraisonclient/${this.routeId}`, requestData, config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log(response);
            this.$store.commit(
              'addMessage',
              {
                message: 'Livraison modifié',
                type: 'success',
              },
            );
          }
          this.wait();
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addMessage',
            {
              message: 'Échec de la modification',
              type: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadBL(deliveryID: number) : void {
      axios.get(`/pdf/bl/${deliveryID}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `JAPORT_livraison_${deliveryID}.pdf`);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    async copyToClipboard(value: string) {
      try {
        await navigator.clipboard.writeText(value);
        this.$store.commit(
          'addMessage',
          {
            message: 'Copié',
            type: 'success',
          },
        );
      } catch ($e) {
        this.$store.commit(
          'addMessage',
          {
            message: 'Echec de l\'action',
            type: 'danger',
          },
        );
      }
    },
  },
});
