<template>
  <h1 class="brandsTitle">Licences</h1>
  <div id="brandsLicences" class="row">
    <div class="flex md3 brandsLicenceItem">
        <div class="item">
          <va-image :src="animalCrossingImage" contain  />
          <span>Animal Crossing</span>
        </div>
      </div>
      <div class="flex md3 brandsLicenceItem">
        <div class="item">
          <va-image :src="dragonBallImage" contain  />
          <span>Dragon Ball</span>
        </div>
      </div>
      <div class="flex md3 brandsLicenceItem">
        <div class="item">
          <va-image :src="ghibliImage" contain  />
          <span>Studio Ghibli</span>
        </div>
      </div>
      <div class="flex md3 brandsLicenceItem">
        <div class="item">
          <va-image :src="kirbyImage" contain  />
          <span>Kirby</span>
        </div>
      </div>
      <div class="flex md3 brandsLicenceItem">
        <div class="item">
          <va-image :src="onePieceImage" contain  />
          <span>One Piece</span>
        </div>
      </div>
      <div class="flex md3 brandsLicenceItem">
        <div class="item">
          <va-image :src="pokemonImage" contain  />
          <span>Pokémon</span>
        </div>
      </div>
      <div class="flex md3 brandsLicenceItem">
        <div class="item">
          <va-image :src="saintSeiyaImage" contain  />
          <span>Saint Seiya</span>
        </div>
      </div>
      <div class="flex md3 brandsLicenceItem">
        <div class="item">
          <va-image :src="sonicImage" contain  />
          <span>Sonic</span>
        </div>
      </div>
      <div class="flex md3 brandsLicenceItem">
        <div class="item">
          <va-image :src="superMarioImage" contain  />
          <span>Super Mario</span>
        </div>
      </div>
      <div class="flex md3 brandsLicenceItem">
        <div class="item">
          <va-image :src="zeldaImage" contain  />
          <span>The Legend of Zelda</span>
        </div>
      </div>
  </div>
  <va-divider>
       <span class="px-2 dividerTitle">Marques</span>
  </va-divider>
  <div id="brands" class="row">
     <div class="flex md3 brandsItem">
        <div class="item">
          <va-image :src="reMentImage" contain  />
          <span>Re-ment</span>
        </div>
      </div>
      <div class="flex md3 brandsItem">
        <div class="item">
          <va-image :src="sanEiBoekiImage" contain  />
          <span>San-ei Boeki</span>
        </div>
      </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import animalCrossingImage from '@/assets/logos/animal_crossing.png';
import dragonBallImage from '@/assets/logos/dragon_ball.png';
import ghibliImage from '@/assets/logos/ghibli.png';
import kirbyImage from '@/assets/logos/kirby.png';
import onePieceImage from '@/assets/logos/one_piece.png';
import pokemonImage from '@/assets/logos/pokemon.png';
import saintSeiyaImage from '@/assets/logos/saint_seiya.png';
import sonicImage from '@/assets/logos/sonic.png';
import superMarioImage from '@/assets/logos/super_mario.png';
import zeldaImage from '@/assets/logos/zelda.png';

import reMentImage from '@/assets/logos/re_ment.png';
import sanEiBoekiImage from '@/assets/logos/san_ei_boeki.jpg';

const data = {
  animalCrossingImage,
  dragonBallImage,
  ghibliImage,
  kirbyImage,
  onePieceImage,
  pokemonImage,
  saintSeiyaImage,
  sonicImage,
  superMarioImage,
  zeldaImage,
  reMentImage,
  sanEiBoekiImage,
};

export default defineComponent({
  name: 'BrandsView',
  data() {
    return data;
  },
});
</script>

<style>
#brandsLicences {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.brandsLicenceItem {
  margin: 20px;
  min-height: 250px;
  min-width: 250px;
}

#brands {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.brandsItem {
  margin: 20px;
  min-height: 250px;
  min-width: 250px;
}

.itemTitle {
  text-align: center;
}

.brandsTitle{
  margin-top: 20px;
  margin-bottom: 20px;
}

.dividerTitle {
  font-size: 12pt;
  font-weight: bold;
}
</style>
