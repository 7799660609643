
import axios from 'axios';
import { defineComponent } from 'vue';
import Address from '@/components/types/Address';
import Product from '@/components/types/Product';
import ProductOrder from '@/components/types/ProductOrder';
import InvoiceList from '@/components/types/InvoiceList';
import Invoice from '@/components/types/Invoice';
import InvoiceWithProducts from '@/components/types/InvoiceWithProducts';
import AccountSidebar from '@/components/AccountSidebar.vue';

export default defineComponent({
  name: 'AccountDeliveries',
  data() {
    const columns = [
      {
        key: 'product.variantPictures',
        name: 'pictures',
        sortable: true,
        label: 'Image',
      },
      { key: 'product.ean', sortable: true, label: 'EAN' },
      { key: 'product.nameFR', sortable: false, label: 'Nom' },
      { key: 'invoiced', sortable: true, label: 'Quantité facturée' },
      {
        key: 'product.price', name: 'price', sortable: true, label: 'Prix (HT)',
      },
      { key: 'totalPrice', sortable: true, label: 'Prix Total (HT)' },
    ];
    const monetaryFormat = new Intl.NumberFormat('fr-CH', {
      style: 'currency',
      currency: 'CHF',

      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers
      // , but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return {
      columns,
      monetaryFormat,
      loading: false,
      invoiceList: new Array<InvoiceList>(),
    };
  },
  components: {
    AccountSidebar,
  },
  created() {
    this.loading = true;

    axios.get('/facture', {
      headers: {
        Authorization: `Bearer ${this.$store.getters.token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          const data = new Array<InvoiceList>();
          Object.entries(response.data).forEach(([key, value]) => {
            const add: Address = this.$store.getters.getAddressForId(key);
            const invs = new Array<InvoiceWithProducts>();

            Object.entries((value as any)).forEach(([keyOrderNumber, valueProduct]) => {
              const prods: Array<ProductOrder> = (valueProduct as any).map((d: any) => {
                const prod: Product = {
                  productID: d.pid,
                  variantID: d.vid,
                  releaseDate: new Date(d.dateSortie),
                  incommingDate: new Date(d.dateArrivage),
                  license: {
                    ID: d.licence,
                    FR: d.LicencesLibelleFR,
                    JP: d.LicencesLibelleJP,
                    EN: d.LicencesLibelleEN,
                    DE: d.LicencesLibelleDE,
                    IT: d.LicencesLibelleIT,
                    master: d.LicencesMaitre,
                  },
                  manufacturer: {
                    ID: d.fabricant,
                    FR: d.FabricantsLibelleFR,
                    JP: d.FabricantsLibelleJP,
                  },
                  price: d.pv,
                  msrp: d.pvc,
                  type: {
                    ID: d.type,
                    FR: d.TypesLibelleFR,
                    JP: d.TypesLibelleJP,
                    EN: d.TypesLibelleEN,
                    DE: d.TypesLibelleIT,
                    IT: d.TypesLibelleIT,
                    master: d.TypesMaitre,
                  },
                  boxOf: Number(d.boiteDe),
                  packingSize: d.colisage,
                  ean: d.ean,
                  weight: d.poids,
                  characters: d.Personnages !== null ? d.Personnages.split(',') : undefined,
                  productPictures: d.PhotosProduit !== null ? d.PhotosProduit.split(',') : undefined,
                  variantPictures: d.PhotosVariante !== null ? d.PhotosVariante.split(',') : undefined,
                  nameFR: d.NomFR,
                  nameEN: d.NomEN,
                  nameDE: d.NomDE,
                  nameIT: d.NomIT,
                  catalog: [],
                  available: d.disponiblePositif,
                  createdAt: new Date(d.VarianteCreatedAt),
                  updatedAt: new Date(d.VarianteUpdatedAt),
                };

                const prodOrder: ProductOrder = {
                  ordered: d.CommandeQuantite,
                  product: prod,
                  invoiced: d.FactureQuantite,
                };

                return prodOrder;
              });
              const invData: Invoice = {
                number: Number(keyOrderNumber),
                date: new Date((valueProduct as any)[0].FactureDate),
                client: Number((valueProduct as any)[0].FactureClient),
                endDate: new Date((valueProduct as any)[0].FactureEcheance),
                total: Number((valueProduct as any)[0].FactureTotal),
                discountPercentage: Number((valueProduct as any)[0].FactureRabaisPourcentage),
                discountAmount: Number((valueProduct as any)[0].FactureRabais),
                VATreduced: Number((valueProduct as any)[0].FactureTVAReduit),
                VAT: Number((valueProduct as any)[0].FactureTVA),
                netTotal: Number((valueProduct as any)[0].FactureTotalNet),
                rounding: Number((valueProduct as any)[0].FactureArrondi),
                grossTotal: Number((valueProduct as any)[0].FactureTotalBrut),
              };

              invs.push({
                invoice: invData,
                products: prods,
              });
            });
            data.push(
              {
                address: add,
                invoices: invs.sort(
                  (
                    a: InvoiceWithProducts,
                    b: InvoiceWithProducts,
                  ) => b.invoice.number - a.invoice.number,
                ),
              },
            );
          });
          this.invoiceList = data;
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    getHeaderForOrder(invoice: Invoice): string {
      return `${invoice.date.toLocaleDateString('fr-CH')}: Facture n° ${invoice.number}. Total: ${this.monetaryFormat.format(invoice.grossTotal)}`;
    },
    downloadFA(invoice: InvoiceWithProducts) : void {
      axios.get(`/pdf/fa/${invoice.invoice.number}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `JAPORT_facture_${invoice.invoice.number}.pdf`);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    downloadXLS(invoice: InvoiceWithProducts) : void {
      axios.get(`/xls/fa/${invoice.invoice.number}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `JAPORT_facture_${invoice.invoice.number}.xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    downloadCSV(invoice: InvoiceWithProducts) : void {
      axios.get(`/csv/fa/${invoice.invoice.number}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `JAPORT_facture_${invoice.invoice.number}.csv`);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    rowClick(event: any) {
      const ProductClicked: Product = event.item.product;
      this.$router.push({
        name: 'Espace Pro - Detail Produits',
        params: { id: ProductClicked.variantID },
      });
    },
  },
});
