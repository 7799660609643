
import { defineComponent } from 'vue';
import MigrationList from '@/components/MigrationList.vue'; // @ is an alias to /src
import DashboardSidebar from '@/components/DashboardSidebar.vue';

const data = {
};

export default defineComponent({
  name: 'ProductDashboardProduct',
  data() {
    return data;
  },
  components: {
    DashboardSidebar,
    MigrationList,
  },
  methods: {
  },
});
