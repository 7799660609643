// eslint-disable-next-line no-shadow
enum State {
    DRAFT,
    PUBLIC,
    ARCHIVED,
    UNKNOWN
// eslint-disable-next-line semi
}

export default State;
