
import { defineComponent } from 'vue';
import DashboardSidebar from '@/components/DashboardSidebar.vue';
import Product from '@/components/types/Product';
import Catalog from '@/components/types/Catalog';
import axios from 'axios';
import License from '@/components/types/License';
import MasterType from '@/components/types/MasterType';
import Manufacturer from '@/components/types/Manufacturer';
import ProductAdmin from '@/components/types/ProductAdmin';

export default defineComponent({
  name: 'ProductDashboard',
  data() {
    const columns = [
      {
        key: 'product.variantPictures',
        name: 'pictures',
        sortable: true,
        label: 'Image',
      },
      { key: 'product.ean', sortable: true, label: 'EAN' },
      {
        key: 'product.manufacturer.FR', name: 'manufacturer', sortable: true, label: 'Fabricant',
      },
      {
        key: 'product.license.FR', name: 'license', sortable: true, label: 'Licence',
      },
      {
        key: 'product.type.FR', name: 'type', sortable: true, label: 'Type',
      },
      {
        key: 'supplier.FR', name: 'supplier', sortable: true, label: 'Fournisseur',
      },
      {
        key: 'product.nameFR', name: 'name', sortable: false, label: 'Nom',
      },
      {
        key: 'product.available', sortable: true, label: 'Stock',
      },
      {
        key: 'JPYPrice', name: 'jpyPrice', sortable: true, label: 'Prix Achat JPY',
      },
      {
        key: 'buyingPrice', name: 'buyingprice', sortable: true, label: 'Prix Achat',
      },
      {
        key: 'product.price', name: 'price', sortable: true, label: 'Prix',
      },
      {
        key: 'product.msrp', name: 'msrp', sortable: true, label: 'Prix Vente Conseillé',
      },
      {
        key: 'product.catalog', name: 'catalog', sortable: false, label: 'Catalogues',
      },
    ];
    const monetaryFormat = new Intl.NumberFormat('fr-CH', {
      style: 'currency',
      currency: 'CHF',

      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers
      // , but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const monetaryFormatJPY = new Intl.NumberFormat('jp-JP', {
      style: 'currency',
      currency: 'JPY',
    });

    return {
      columns,
      monetaryFormat,
      monetaryFormatJPY,
      loading: false,
      products: new Array<ProductAdmin>(),
      filteredProducts: new Array<ProductAdmin>(),
      optionsLicenses: new Array<License>(),
      selectionsLicenses: new Array<License>(),
      optionsTypes: new Array<MasterType>(),
      selectionsTypes: new Array<MasterType>(),
      optionsManufacturers: new Array<Manufacturer>(),
      selectionsManufacturers: new Array<Manufacturer>(),
      inputFilterDebounced: '',
      timeout: 0,
      selectedProducts: new Array<ProductAdmin>(),
      radioGalaxusOptions: [
        'no',
        'both',
        'yes',
      ],
      filters: {
        input: '',
        gtinCheckbox: false,
        stockCheckbox: false,
        selectedGalaxusOption: 'both',
      },
    };
  },
  components: {
    DashboardSidebar,
  },
  watch: {
    filters: {
      handler(newValue, oldValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.applyFilters();
      },
      deep: true,
    },
    inputFilterDebounced: {
      handler(newInput) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.setFilterInput, 300, newInput);
      },
    },
  },
  created() {
    this.getData();
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      const fetchFunction = (url:string) => axios.get(url, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      });

      const URLStock = '/etatstock';
      const URLLicenses = '/licence';
      const URLTypes = '/maitretype';
      const URLManufacturers = '/fabricant';

      const promiseArray = [URLStock, URLLicenses, URLTypes, URLManufacturers]
        .map(fetchFunction);

      Promise.all(promiseArray)
        .then((data) => {
          this.products = data[0].data.map((d: any) => {
            const catalogs = new Array<Catalog>();

            if (d.CatalogueIDS !== null) {
              const catalogIDs = d.CatalogueIDS.split(';');
              const catalogNames = d.CatalogueLibelles.split(';');
              let catalogEndDates = new Array<Date>();
              if (d.CatalogeDatesFins !== null) {
                catalogEndDates = d.CatalogeDatesFins.split(';');
              }
              for (let index = 0; index < catalogIDs.length; index += 1) {
                const catalog: Catalog = {
                  ID: catalogIDs[index],
                  name: catalogNames[index],
                  endDate: catalogEndDates[index],
                };
                catalogs.push(catalog);
              }
            }

            const prod: Product = {
              productID: d.pid,
              variantID: d.vid,
              releaseDate: new Date(d.dateSortie),
              incommingDate: new Date(d.dateArrivage),
              license: {
                ID: d.licence,
                FR: d.LicencesLibelleFR,
                JP: d.LicencesLibelleJP,
                EN: d.LicencesLibelleEN,
                DE: d.LicencesLibelleDE,
                IT: d.LicencesLibelleIT,
                master: d.LicencesMaitre,
              },
              manufacturer: {
                ID: d.fabricant,
                FR: d.FabricantsLibelleFR,
                JP: d.FabricantsLibelleJP,
              },
              price: d.pv,
              msrp: d.pvc,
              type: {
                ID: d.type,
                FR: d.TypesLibelleFR,
                JP: d.TypesLibelleJP,
                EN: d.TypesLibelleEN,
                DE: d.TypesLibelleIT,
                IT: d.TypesLibelleIT,
                master: d.TypesMaitre,
              },
              boxOf: d.boiteDe,
              packingSize: d.colisage,
              ean: d.ean,
              weight: d.poids,
              characters: d.Personnages !== null ? d.Personnages.split(',') : undefined,
              productPictures: d.PhotosProduit !== null ? d.PhotosProduit.split(',') : undefined,
              variantPictures: d.PhotosVariante !== null ? d.PhotosVariante.split(',') : undefined,
              nameFR: d.NomFR,
              nameEN: d.NomEN,
              nameDE: d.NomDE,
              nameIT: d.NomIT,
              catalog: catalogs,
              available: d.disponiblePositif,
              createdAt: new Date(d.VarianteCreatedAt),
              updatedAt: new Date(d.VarianteUpdatedAt),
            };

            const prodAdmin: ProductAdmin = {
              product: prod,
              JPYPrice: d.prixJPY,
              JPYDiscountPrixe: d.rabaisJPY,
              buyingPrice: d.pa,
              supplier: { ID: d.fournisseur, FR: d.Entreprise },
              received: d.recu, // # total received
              delivered: d.livrer, // # total delivered
              incomming: d.arrivage, // # total on the way
              clientOrder: d.commandeClient, // # total ordered by client
              supplierOrder: d.commandeFournisseur,
              clientCancelledOrder: d.annulationCommandeClient,
              supplierCancelledOrder: d.annulationFournisseur,
            };

            return prodAdmin;
          });
          this.optionsLicenses = data[1].data.map((d:any) => ({
            ID: d.id,
            FR: d.libelleFR,
            JP: d.libelleJP,
            EN: d.libelleEN,
            DE: d.libelleDE,
            IT: d.libelleIT,
            master: d.maitreLicence,
          }));
          this.optionsTypes = data[2].data.map((d:any) => ({
            ID: d.id,
            FR: d.libelleFR,
            JP: d.libelleJP,
            EN: d.libelleEN,
            DE: d.libelleDE,
            IT: d.libelleIT,
          }));
          this.optionsManufacturers = data[3].data.map((d:any) => ({
            ID: d.id,
            FR: d.libelleFR,
            JP: d.libelleJP,
          }));

          this.filteredProducts = this.products;
          this.applyFilters();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => { this.loading = false; });
    },
    displayItem(a:any) {
      console.log(a);
    },
    setFilterInput(value: string) {
      this.filters.input = value;
    },
    filterGalaxusCatalog(product:any) : boolean {
      if (this.filters.selectedGalaxusOption === 'no') {
        return product.catalog.findIndex((catalog: any) => catalog.ID === '1') === -1;
      }

      if (this.filters.selectedGalaxusOption === 'yes') {
        return product.catalog.findIndex((catalog: any) => catalog.ID === '1') !== -1;
      }

      return true;
    },
    applyFilters() {
      this.filteredProducts = this.products.filter(
        (product:any) => {
          if (this.filters.input.length === 0 && this.filters.gtinCheckbox === false && this.filters.selectedGalaxusOption === 'both') {
            return true;
          }

          return (
            (
              this.filters.input.length === 0 || (
                product.product.nameFR.toUpperCase().includes(this.filters.input.toUpperCase())
              || product.product.ean.toString().toUpperCase().includes(
                this.filters.input.toUpperCase(),
              ))
            )
            && (
              this.filters.gtinCheckbox === false || (!product.product.ean.toString().startsWith('2') && product.product.ean.toString().length === 13)
            )
            && (
              this.filters.stockCheckbox === false || (product.product.available > 0)
            )
            && (
              this.filters.selectedGalaxusOption === 'both' || this.filterGalaxusCatalog(product.product)
            )
          );
        },
      );
    },
    addSelectedProductToGalaxus() {
      this.loading = true;
      // TODO check that the product is well formed for Galaxus
      // meaning All license and type etc.. has been translated
      // weight is not 100
      // Category is good
      const prodIds = this.selectedProducts.map((prod) => prod.product.productID);
      const uniqueProdIds = [...new Set(prodIds)];

      console.log(uniqueProdIds);

      const postFunction = (url:string, config: any, data: any) => axios.post(url, data, config);
      if (uniqueProdIds !== undefined && uniqueProdIds.length > 0) {
        const promiseArray = uniqueProdIds.map((id) => {
          const URL = '/catalogueproduit';

          const config = {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          };

          const postData = JSON.stringify({
            produit: id,
            catalogue: 1,
          });

          return postFunction(URL, config, postData);
        });

        Promise.all(promiseArray)
          .then((data) => {
            this.$store.commit(
              'addMessage',
              {
                message: 'Produit ajouté au catalogue',
                type: 'success',
              },
            );

            this.getData();
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit(
              'addMessage',
              {
                message: 'Échec de l\'ajout',
                type: 'danger',
              },
            );
          })
          .finally(() => { this.loading = false; });
      }
    },
  },
});
