<template>
  <va-navbar color="primary" shape class="mb-2" text-color="white" v-if="$windowWidth > 768">
    <template #left>
      <va-navbar-item>
      </va-navbar-item>
    </template>
    <template #center>
      <va-navbar-item>
        <va-image
          contain
          class="logo"
          :src="logo" />
      </va-navbar-item>
    </template>
    <template #right>
      <va-navbar-item></va-navbar-item>
    </template>
  </va-navbar>
  <div id="logoNav" class="row" v-else>
    <va-image contain class="logo" :src="logo" />
  </div>
  <div id="nav" class="row" v-if="!isLoggedIn">
    <router-link to="/">Accueil</router-link>
    <va-divider vertical />
    <router-link to="/brands">Nos Marques et Licences</router-link>
    <va-divider vertical />
    <router-link to="/login">Espace Pro</router-link>
    <va-divider vertical />
    <router-link to="/conditionGeneral">Conditions Générales</router-link>
    <va-divider vertical />
    <router-link to="/contact">Contact</router-link>
  </div>
  <div style="position: relative;" v-else>
    <va-app-bar>
      <va-button
       icon="store"
       flat
       to="/pro/products">
        Produits
      </va-button>
      <va-button
        icon="sell"
        flat
        v-if="isAdmin"
        to="/pro/sell">
          Vente Direct
      </va-button>
      <va-button
        icon="store"
        flat
        v-if="isAdmin"
        to="/supplier">
          C&A Fournisseur
      </va-button>
      <va-button
        icon="store"
        flat
        v-if="isAdmin"
        to="/client">
          C&L Client
      </va-button>
      <va-button
        icon="autorenew"
        flat
        v-if="isAdmin"
        to="/pro/managment">
        Gestion des produits
      </va-button>
      <va-spacer />
      <va-progress-circle v-if="isBackgroundLoading" indeterminate />
      <va-button icon="shopping_cart_checkout" to="/pro/cart">
        Panier
      </va-button>
      <va-button-dropdown
       v-if="this.$store.getters.user.company !== undefined"
       @main-button-click="goToAccount()"
       split>
        <template #label>
          <va-icon name="account_circle" style="margin-right: 5px;"/>
          {{ this.$store.getters.user.company.name }}
        </template>
        <va-list fit>
          <va-list-item>
            <va-button
              class="accountButton listItemButton"
              to="/account"
            >
              Mon compte
            </va-button>
          </va-list-item>
          <va-list-item>
            <va-button
              class="accountButton listItemButton"
              to="/account/order"
            >
              Mes commandes
            </va-button>
          </va-list-item>
          <va-list-item>
            <va-button
              class="accountButton listItemButton"
              to="/account/delivery"
            >
              Mes livraisons
            </va-button>
          </va-list-item>
          <va-list-item>
            <va-button
              class="accountButton listItemButton"
              to="/account/invoices"
            >
              Mes factures
            </va-button>
          </va-list-item>
        </va-list>
      </va-button-dropdown>
      <va-button-dropdown
        v-if="this.$store.getters.addresses !== undefined
          && this.$store.getters.addresses.length > 1"
        split
      >
      <template #label>
          <va-icon name="local_shipping" style="margin-right: 5px;"/>
          {{ this.$store.getters.getSelectedAddress !== undefined
         ? this.$store.getters.getSelectedAddress.name
         : '' }}
        </template>
      <va-list fit>
        <va-list-item
            v-for="address in this.$store.getters.addresses"
            :key="address.ID">
          <va-button
            @click="setSelectedAddress(address)"
            class="listItemButton"
            >
              {{ address.name }}
          </va-button>
        </va-list-item>
      </va-list>
      </va-button-dropdown>
      <va-button
        icon="logout"
        @click="logout()">
        Déconnexion
      </va-button>
    </va-app-bar>
  </div>
  <va-divider />
  <router-view/>
</template>

<script>
import { defineComponent } from 'vue';
import User from '@/components/types/User';
import ImageLogo from '@/assets/logo_100h.png';

const data = {
  logo: ImageLogo,
};

export default defineComponent({
  name: 'App',
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return data;
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    toastMessage() {
      return this.$store.getters.message;
    },
    user() {
      return this.$store.getters.user;
    },
    isAdmin() {
      return Number(this.$store.getters.user.admin) === 1;
    },
  },
  watch: {
    toastMessage: {
      handler(newMessage) {
        if (newMessage !== null) {
          this.$vaToast.init(({
            message: newMessage.message,
            color: newMessage.type,
            duration: 1500,
          }));
          this.$store.dispatch('clearMessage');
        }
      },
    },
  },
  methods: {
    setSelectedAddress(newAddress) {
      this.$store.dispatch('setSelectedAddress', newAddress);
    },
    goToAccount() {
      this.$router.push({ path: '/account' });
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({
        name: 'Login',
      });
    },
  },
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
}

#logoNav {
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

#nav {
  justify-content: center;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #BC002D;
}

.logo {
  max-height: 50px;
  height: 50px;
  width: 157px;
  z-index: 1;
}

.va-navbar__background-shape {
  border-top-color: rgb(255, 255, 255) !important;
}

.accountButton {
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
}

.listItemButton {
  margin-top: 5px !important;
  width: 100% !important;
}

html, body {
  margin:0;
  padding:0;
  height:100%;
}

#nav {
  max-height: 20px;
}
</style>
