
import { defineComponent } from 'vue';
import SupplierSidebar from '@/components/SupplierSidebar.vue';
import axios from 'axios';
import Incomming from '@/components/types/Incomming';
import Parcel from '@/components/types/Parcel';
import Address from '@/components/types/Address';

export default defineComponent({
  name: 'SupplierDashboard',
  data() {
    const columns = [
      { key: 'date', sortable: true, label: 'Date' },
      { key: 'origin.name', sortable: true, label: 'Origine' },
      { key: 'parcels', sortable: true, label: 'Cartons en attente' },
    ];
    const monetaryFormat = new Intl.NumberFormat('fr-CH', {
      style: 'currency',
      currency: 'CHF',

      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers
      // , but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return {
      columns,
      monetaryFormat,
      loading: false,
      incommingList: new Array<Incomming>(),
    };
  },
  components: {
    SupplierSidebar,
  },
  created() {
    this.loading = true;

    axios.get('/arrivagefournisseur/open', {
      headers: {
        Authorization: `Bearer ${this.$store.getters.token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          const data = new Array<Incomming>();

          Object.entries(response.data).forEach(([key, value]) => {
            const parcels: Array<Parcel> = (value as any).cartons.map((d: any) => {
              const parcel: Parcel = {
                ID: d.CartonID,
                trackingNumber: d.CartonTracking,
              };
              return parcel;
            });

            const add: Address = this.$store.getters.getAddressForId((value as any).provenance);

            data.push(
              {
                ID: Number(key),
                date: new Date((value as any).date),
                origin: add,
                parcels,
              },
            );
          });
          this.incommingList = data;
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    displayParcels(rowIndex: number):string {
      // return this.incommingList[rowIndex].parcels.map((p) => p.ID).join(',');
      return `${this.incommingList[rowIndex].parcels.length} cartons`;
    },
    rowClick(event: any) {
      const incommingClicked: Incomming = event.item;
      console.log(incommingClicked);
      this.$router.push({
        name: 'Espace Pro - Arrivage Fournisseur',
        params: { id: incommingClicked.ID },
      });
    },
  },
});
