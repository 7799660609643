import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { createVuestic } from 'vuestic-ui';
import { VueWindowSizePlugin } from 'vue-window-size/option-api';
import 'vuestic-ui/dist/vuestic-ui.css';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

axios.defaults.baseURL = 'https://api.japort.ch';
axios.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  (response) => response,
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  (error) => {
    if (error.response.status === 401) {
      store.commit(
        'addMessage',
        {
          message: 'Problème de connexion. Veuillez vous reconnecter',
          type: 'error',
        },
      );
      store.dispatch('logout');
      router.push({
        name: 'Login',
        query: {
          redirect: '/pro/products',
        },
      });
    }
    return Promise.reject(error);
  },
);

createApp(App).use(store).use(router).use(VueAxios, axios)
  .use(VueWindowSizePlugin)
  .use(createVuestic({
    config: {
      colors: {
        variables: {
          primary: '#BC002D',
        },
      },
    },
  }))
  .mount('#app');
