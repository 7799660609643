<template>
  <div>
    <div class="home">
        <img id="homeBanner" :src="homeImage" alt="Japan and Swiss Flag">
    </div>
    <div id="homeText" class="row">
      <div class="flex md4 homeTextItem">
          <div class="item">
            <h2 class="itemTitle">English</h2>
            <p>
              <strong>Japort SA</strong> is a Swiss Limited Company specialized
              in importing Japanese products.
              In particular, we import products under anime, mangas, or Japanese video
              games licenses.
              We distribute our products solely
              to Swiss Professional Retailers.
              The strength of <strong>Japort SA</strong> is to always offer the latest
              products to their clients at the best prices.
              In the current difficult context of importing goods from Asia, <strong>
              Japort SA</strong> thrives  that their products are delivered without delay
              and as quickly  as possible.
            </p>
            <br/>
            <p>If you wish to have more information or to obtain access to our catalog,
            please write us a message through our contact
            <router-link to="/contact">form</router-link>.
            </p>
          </div>
        </div>
        <div class="flex md4 homeTextItem">
          <div class="item">
            <h2 class="itemTitle">Français</h2>
              <p><strong>Japort SA</strong> est une entreprise Suisse spécialisée
              dans l'import de produits Japonais.
              En particulier, les produits sous licences d'animé, de manga
              ou de jeux vidéo Japonais.
              Nous distribuons nos produits uniquement aux professionnels de la vente
              Suisse.
              La force de <strong>Japort SA</strong> est de toujours offrir
              les dernières nouveautés à ses clients au meilleur prix.
              Dans le contexte difficile actuelle de l'importation de marchandises
              venant d'Asie, <strong>Japort SA</strong> s'efforce que ses
              produits soient livrés sans retard et dans les meilleurs délai.</p>
              <br/>
              <p>Pour plus d'informations ou pour obtenir notre catalogue, nous vous
              prions de bien vouloir nous contacter sur notre page de
              <router-link to="/contact">Contact</router-link>.</p>
          </div>
        </div>
        <div class="flex md4 homeTextItem">
          <div class="item" id="home-japanese-introduction">
            <h2 class="itemTitle">日本語</h2>
            <p><strong>Japort SA</strong> はスイスの会社です。日本からスイスまで日本の物を輸入しています。それは物の公認だけです。手持ちの
            <strong>Japort SA</strong> は日本の物の店にだけ売っています。日本の物は一般に新しいです。今日本から輸入は難しいです。でも顧客の
            <strong>Japort SA</strong> に素早い配達を配ってします。</p>
            <br/>
            <p>もっと質問がありますか？<router-link to="/contact">メイル</router-link>を下さい。</p>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import homeImage from '@/assets/home.png';
// import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

const data = {
  homeImage,
};

export default defineComponent({
  name: 'HomeView',
  components: {
    // HelloWorld,
  },
  data() {
    return data;
  },
});
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');

#homeText {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.homeTextItem {
  padding:20px;
  text-align: justify;
}

.itemTitle {
  text-align: center;
}

#homeBanner {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

#home-japanese-introduction {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: larger;
}

</style>
