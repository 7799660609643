
import { defineComponent } from 'vue';
import SupplierSidebar from '@/components/SupplierSidebar.vue';
import axios from 'axios';
import Incomming from '@/components/types/Incomming';
import Parcel from '@/components/types/Parcel';
import Address from '@/components/types/Address';
import Product from '@/components/types/Product';
import ParcelProducts from '@/components/types/ParcelProducts';

export default defineComponent({
  name: 'SupplierIncomming',
  data() {
    const columns = [
      {
        key: 'product.variantPictures',
        name: 'pictures',
        sortable: true,
        label: 'Image',
      },
      {
        key: 'product.ean', name: 'ean', sortable: true, label: 'EAN',
      },
      {
        key: 'product.weight', name: 'weight', sortable: false, label: 'Poids',
      },
      { key: 'product.manufacturer.FR', sortable: true, label: 'Fabricant' },
      { key: 'product.nameFR', sortable: false, label: 'Nom' },
      {
        key: 'product.price', name: 'price', sortable: true, label: 'Prix (HT)',
      },
      {
        key: 'expectedQuantity', sortable: true, label: 'Arrivage',
      },
      {
        key: 'receivedQuantity', sortable: true, label: 'Reçu',
      },
      {
        key: 'actions', sortable: true, label: '',
      },
    ];

    const columnsIncommings = [
      { key: 'date', sortable: true, label: 'Date' },
      { key: 'origin.name', sortable: true, label: 'Origine' },
      { key: 'parcels', sortable: true, label: 'Cartons' },
    ];

    const monetaryFormat = new Intl.NumberFormat('fr-CH', {
      style: 'currency',
      currency: 'CHF',

      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers
      // , but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return {
      columns,
      columnsIncommings,
      monetaryFormat,
      loading: false,
      incomming: {} as Incomming,
      incommingList: new Array<Incomming>(),
      weightEditRow: Array<boolean>(),
      expectedEditRow: Array<boolean>(),
      timeoutExpected: {} as any,
      timeoutReceived: {} as any,
    };
  },
  components: {
    SupplierSidebar,
  },
  computed: {
    routeId() {
      return this.$route.params.id !== undefined ? Number(this.$route.params.id) : undefined;
    },
  },
  watch: {
    $route(to, from) {
      if (this.routeId !== undefined) {
      // load one incomming in particular
        this.getIncommingData(this.routeId);
      } else {
        this.getIncommings();
      }
    },
  },
  mounted() {
    if (this.routeId !== undefined) {
      // load one incomming in particular
      this.getIncommingData(this.routeId);
    } else {
      this.getIncommings();
    }
  },
  created() {
    console.log(`Incomming ${this.routeId}`);
    if (this.routeId !== undefined) {
      // load one incomming in particular
      this.getIncommingData(this.routeId);
    } else {
      this.getIncommings();
    }
  },
  methods: {
    getIncommingData(ID:number) {
      this.loading = true;

      return axios.get(`/arrivagefournisseur/${ID}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            Object.entries(response.data).forEach(([key, value]) => {
              const data = new Array<Parcel>(); // parcel data

              Object.entries((value as any).cartons).forEach(([keyCarton, valueCarton]) => {
                const products: Array<ParcelProducts> = (valueCarton as any).produits
                  .map((d: any) => {
                    const prod: Product = {
                      productID: d.pid,
                      variantID: d.vid,
                      releaseDate: new Date(d.dateSortie),
                      incommingDate: new Date(d.dateArrivage),
                      license: {
                        ID: d.licence,
                        FR: d.LicencesLibelleFR,
                        JP: d.LicencesLibelleJP,
                        EN: d.LicencesLibelleEN,
                        DE: d.LicencesLibelleDE,
                        IT: d.LicencesLibelleIT,
                        master: d.LicencesMaitre,
                      },
                      manufacturer: {
                        ID: d.fabricant,
                        FR: d.FabricantsLibelleFR,
                        JP: d.FabricantsLibelleJP,
                      },
                      price: d.pv,
                      msrp: d.pvc,
                      type: {
                        ID: d.type,
                        FR: d.TypesLibelleFR,
                        JP: d.TypesLibelleJP,
                        EN: d.TypesLibelleEN,
                        DE: d.TypesLibelleIT,
                        IT: d.TypesLibelleIT,
                        master: d.TypesMaitre,
                      },
                      boxOf: Number(d.boiteDe),
                      packingSize: d.colisage,
                      ean: d.ean,
                      weight: d.poids,
                      characters: d.Personnages !== null ? d.Personnages.split(',') : undefined,
                      productPictures: d.PhotosProduit !== null ? d.PhotosProduit.split(',') : undefined,
                      variantPictures: d.PhotosVariante !== null ? d.PhotosVariante.split(',') : undefined,
                      nameFR: d.NomFR,
                      nameEN: d.NomEN,
                      nameDE: d.NomDE,
                      nameIT: d.NomIT,
                      catalog: [],
                      available: d.disponiblePositif,
                      createdAt: new Date(d.VarianteCreatedAt),
                      updatedAt: new Date(d.VarianteUpdatedAt),
                    };

                    return {
                      ID: d.CartonLigneID,
                      supplierOrderID: d.commandeFournisseurID,
                      expectedQuantity: d.quantiteArrivage,
                      receivedQuantity: d.quantiteRecue,
                      product: prod,
                    }; // return ParcelProduct
                  });

                const par:Parcel = {
                  ID: (valueCarton as any).CartonID,
                  trackingNumber: (valueCarton as any).CartonTracking,
                  products,
                };

                data.push(par); // push Parcel into Array
              });

              const add: Address = this.$store.getters.getAddressForId((value as any).provenance);

              this.incomming = {
                ID: Number(key),
                date: new Date((value as any).date),
                origin: add,
                parcels: data,
              };
            });
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getIncommings() {
      this.loading = true;

      axios.get('/arrivagefournisseur', {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            const data = new Array<Incomming>();

            Object.entries(response.data).forEach(([key, value]) => {
              console.log(value);
              const parcels: Array<Parcel> = (value as any).cartons.map((d: any) => {
                const parcel: Parcel = {
                  ID: d.CartonID,
                  trackingNumber: d.CartonTracking,
                };
                return parcel;
              });

              const add: Address = this.$store.getters.getAddressForId((value as any).provenance);

              data.push(
                {
                  ID: Number(key),
                  date: new Date((value as any).date),
                  origin: add,
                  parcels,
                },
              );
            });
            this.incommingList = data;
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateReceived(ID: number, quantity: number) {
      clearTimeout(this.timeoutReceived);
      this.timeoutReceived = setTimeout(this.updateReceviedById, 1000, ID, quantity);
    },
    updateReceviedById(ID: number, quantity: number) {
      console.log(`Update ${ID} with quantity ${quantity}`);

      this.loading = true;

      const requestData = JSON.stringify({
        quantiteRecue: quantity,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      };

      axios.put(`/cartonsarrivageligne/${ID}`, requestData, config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log(response);
            this.$store.commit(
              'addMessage',
              {
                message: 'Arrivage modifié',
                type: 'success',
              },
            );
          }
          if (this.routeId !== undefined) {
            this.getIncommingData(this.routeId);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addMessage',
            {
              message: 'Échec de la modification',
              type: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateExpected(ID: number, quantity: number) {
      clearTimeout(this.timeoutExpected);
      this.timeoutExpected = setTimeout(this.updateExpectedById, 1000, ID, quantity);
    },
    updateExpectedById(ID: number, quantity: number) {
      this.loading = true;

      const requestData = JSON.stringify({
        quantiteArrivage: quantity,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      };

      axios.put(`/cartonsarrivageligne/${ID}`, requestData, config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log(response);
            this.$store.commit(
              'addMessage',
              {
                message: 'Arrivage modifié',
                type: 'success',
              },
            );
          }
          if (this.routeId !== undefined) {
            this.getIncommingData(this.routeId);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addMessage',
            {
              message: 'Échec de la modification',
              type: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateWeight(ID: number, weight: number, parcelID:number, index: number) {
      this.loading = true;

      const requestData = JSON.stringify({
        poids: weight,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
        },
      };

      axios.put(`/variante/${ID}`, requestData, config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log(response);
            this.$store.commit(
              'addMessage',
              {
                message: 'Poids modifié',
                type: 'success',
              },
            );
          }
          if (this.routeId !== undefined) {
            this.getIncommingData(this.routeId);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addMessage',
            {
              message: 'Échec de la modification',
              type: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
          this.doneWeightRow(parcelID, index);
        });
    },
    rowClick(varianteID: number) {
      this.$router.push({
        name: 'Espace Pro - Detail Produits',
        params: { id: varianteID },
      });
    },
    async copyToClipboard(value: string) {
      try {
        await navigator.clipboard.writeText(value);
        this.$store.commit(
          'addMessage',
          {
            message: 'Copié',
            type: 'success',
          },
        );
      } catch ($e) {
        this.$store.commit(
          'addMessage',
          {
            message: 'Echec de l\'action',
            type: 'danger',
          },
        );
      }
    },
    editExpectedRow(parcelID:number, index: number) {
      const computedIndex = this.computeIndex(parcelID, index);
      this.expectedEditRow[computedIndex] = true;
    },
    doneExpectedRow(parcelID:number, index: number) {
      const computedIndex = this.computeIndex(parcelID, index);
      this.expectedEditRow[computedIndex] = false;
    },
    editWeightRow(parcelID:number, index: number) {
      const computedIndex = this.computeIndex(parcelID, index);
      this.weightEditRow[computedIndex] = true;
    },
    doneWeightRow(parcelID:number, index: number) {
      const computedIndex = this.computeIndex(parcelID, index);
      this.weightEditRow[computedIndex] = false;
    },
    expectedRowIsEditable(parcelID:number, index: number): boolean {
      const computedIndex = this.computeIndex(parcelID, index);
      return this.expectedEditRow[computedIndex];
    },
    weightRowIsEditable(parcelID:number, index: number): boolean {
      const computedIndex = this.computeIndex(parcelID, index);
      return this.weightEditRow[computedIndex];
    },
    computeIndex(parcelID:number, index: number): number {
      return parcelID * 100 + index;
    },
    displayParcels(rowIndex: number):string {
      // return this.incommingList[rowIndex].parcels.map((p) => p.ID).join(',');
      return `${this.incommingList[rowIndex].parcels.length} cartons`;
    },
    rowClickIncommings(event: any) {
      const incommingClicked: Incomming = event.item;
      console.log(incommingClicked);
      this.$router.push({
        name: 'Espace Pro - Arrivage Fournisseur',
        params: { id: incommingClicked.ID },
      });
    },
    parcelDone(parcel:Parcel) {
      this.loading = true;

      const putFunction = (url:string, config: any, data: any) => axios.put(url, data, config);

      if (parcel.products !== undefined) {
        const promiseArray = parcel.products.map((product) => {
          const URL = `/cartonsarrivageligne/${product.ID}`;

          const config = {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          };

          const requestData = JSON.stringify({
            quantiteArrivage: product.expectedQuantity,
          });

          return putFunction(URL, config, requestData);
        });

        Promise.all(promiseArray)
          .then((data) => {
            this.$store.commit(
              'addMessage',
              {
                message: 'Cartons modifié',
                type: 'success',
              },
            );

            if (this.routeId !== undefined) {
              this.getIncommingData(this.routeId);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit(
              'addMessage',
              {
                message: 'Échec de la modification',
                type: 'danger',
              },
            );
          })
          .finally(() => { this.loading = false; });
      }
    },
  },
});
