<template>
  <div class="proProducts">
    <ProductShop class="productList"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import ProductShop from '@/components/ProductShop.vue'; // @ is an alias to /src

const data = {
};

export default defineComponent({
  name: 'ProductsView',
  data() {
    return data;
  },
  components: {
    ProductShop,
  },
});
</script>

<style>
</style>
